define("knowme-client/routes/pricing", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let KnowmeIQProductRoute;

  KnowmeIQProductRoute = Ember.Route.extend({
    session: Ember.inject.service(),
    actions: {}
  });

  exports.default = KnowmeIQProductRoute;
});