define("knowme-client/routes/account/settings/deals/new", ["exports", "knowme-client/mixins/routes/account-setup"], function (exports, _accountSetup) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    queryParams: {
      from: {
        refreshModel: true
      }
    },
    dealAttributes: {},
    beforeModel: function (transition) {
      return this._super.apply(this, arguments).then(function () {
        return this.get("store").queryRecord("stripe-account", {}).then(function (stripeAccount) {
          if (Ember.isPresent(stripeAccount) && stripeAccount.get("isActive") && stripeAccount.get("payoutsEnabled")) {
            localStorage.removeItem("openPaymentIntegration");
          } else {
            localStorage.setItem("openPaymentIntegration", true);
            return this.transitionTo("account");
          }
        }.bind(this));
      }.bind(this));
    },
    // beforeModel: function (transition) {
    //   let dealAttributes = this.controllerFor("account.settings.deals").get(
    //     "dealAttributes"
    //   );
    //   if (isPresent(transition.queryParams.from) && isPresent(dealAttributes)) {
    //     this.set("dealAttributes", dealAttributes);
    //   }
    // },
    model: function () {
      return this.store.createRecord("deal", this.get("dealAttributes"));
    },

    afterModel: function () {
      return Ember.RSVP.hash({
        products: this.get("store").query("product", {
          paginate: false,
          sort_by: "name",
          order: "asc",
          status: "active"
        })
      }).then(function (results) {
        this.set("controllerData", results);
      }.bind(this));
    },
    setupController: function (controller) {
      this._super(...arguments);
      controller.setProperties(this.get("controllerData"));
      this.set("controllerData", {});
    },
    actions: {
      willTransition: function () /*transition*/{
        let model = this.controller.get("model");
        if (model.get("isNew")) {
          model.deleteRecord();
        }
      }
    }
  });
});