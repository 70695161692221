define("knowme-client/routes/dashboard", ["exports", "knowme-client/mixins/routes/account-setup", "knowme-client/mixins/routes/cable-setup", "knowme-client/config/environment", "lodash"], function (exports, _accountSetup, _cableSetup, _environment, _lodash) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var DashboardRoute;

  DashboardRoute = Ember.Route.extend(_accountSetup.default, _cableSetup.default, {
    session: Ember.inject.service("session"),
    features: Ember.inject.service("features"),
    cableService: Ember.inject.service("cable"),
    channelName: "CustomerExperienceChannel",
    appointmentSalesStage: null,
    beforeModel: function (transition) {
      return this._super.apply(this, arguments).then(function () {
        return this.set("subscriptionData", {
          id: this.get("session.account.id")
        });
      }.bind(this)).then(function () {
        return this.get("store").query("sales-stage", { key: "appointment" });
      }.bind(this)).then(function (appointments) {
        return this.set("appointmentSalesStage", appointments.get("firstObject"));
      }.bind(this));
    },
    model: function () {
      let currentUser = this.get("session.currentUser");
      return currentUser.get("usersDashboardWidgets").reload();
    },
    afterModel: function (model) {
      let adapter = Ember.getOwner(this).lookup("adapter:application");

      let urls = {
        adNetworkSummaryUrl: adapter.buildURL("deals/summary"),
        campaignsSummaryUrl: adapter.buildURL("campaign/summary"),
        customersSummaryUrl: adapter.buildURL("customer/summary"),
        leadsSummaryUrl: adapter.buildURL("lead/summary"),
        salesStagesSummaryUrl: adapter.buildURL("opportunity/summary"),
        contactsSatisfactionSummaryUrl: adapter.buildURL("customer_experiences/summary")
      };

      let adapters = [];
      model.forEach(function (widget) {
        let key = _lodash.default.camelCase(widget.get("dashboardWidget.key")) + "Summary";
        if (key === "reportsSummary") {
          adapters.push("customersSummaryUrl");
          adapters.push("leadsSummaryUrl");
        }
        if (urls[key + "Url"]) {
          adapters.push(key + "Url");
        }
      });
      let promises = [];
      adapters.uniq().forEach(function (key) {
        promises.push(adapter.ajax(urls[key]));
      });

      let self = this;
      return new Ember.RSVP.Promise(function (resolve /*, reject */) {
        Ember.RSVP.all(promises).then(function (response) {
          let result = {};
          response.forEach(function (resp) {
            _lodash.default.merge(result, resp);
          });
          self.set("controllerData", { summary: result });
          resolve(result);
        });
      });
    },
    setupController: function (controller) {
      // Call _super for default behavior
      this._super(...arguments);
      controller.set("appointmentSalesStage", this.get("appointmentSalesStage"));
      controller.setProperties(this.get("controllerData"));
      controller.init();

      let path = `${_environment.default.APP.Websocket.protocol}://${_environment.default.APP.Websocket.host}/cable`;
      let leadConsumer = this.get("cableService").createConsumer(path);
      const leadSubscription = leadConsumer.subscriptions.create("LeadChannel", {
        connected() {
          this.perform("follow", {
            id: controller.get("session.currentUser.account.id")
          });
        },
        received(resp) {
          let adapter = Ember.getOwner(this).lookup("adapter:application");
          adapter.ajax(adapter.buildURL("lead/summary")).then(function (res) {
            controller.set("summary.leadsSummary", res.leadsSummary);
          });
          adapter.ajax(adapter.buildURL("customer/summary")).then(function (res) {
            controller.set("summary.customersSummary", res.customersSummary);
          });
        },
        disconnected() {
          console.debug("disconnected");
        }
      });
      controller.set("leadSubscription", leadSubscription);
      controller.set("leadConsumer", leadConsumer);
      this.set("controllerData", {});
    },
    resetController: function (controller, isExiting /*, transition*/) {
      this._super(...arguments);
      if (isExiting) {
        localStorage.removeItem("firstTimeDashboardOpened");
        controller.set("displayTodaysAppointments", false);
        controller.set("marginLeft", 0);

        if (this.controller.get("leadSubscription")) {
          this.controller.get("leadSubscription").unsubscribe();
        }
        if (this.controller.get("leadConsumer")) {
          this.controller.get("leadConsumer").destroy();
        }
      }
    },
    afterReceived: function (controller, payload, action) {
      let adapter = Ember.getOwner(this).lookup("adapter:application");
      adapter.ajax(adapter.buildURL("customer_experiences/summary")).then(function (res) {
        controller.set("summary.customerExperiencesSummary", res.customerExperiencesSummary);
      });
    }
  });

  exports.default = DashboardRoute;
});