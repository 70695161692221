define("knowme-client/controllers/leads/index", ["exports", "knowme-client/mixins/date-filter", "knowme-client/mixins/controllers/queryable", "knowme-client/mixins/controllers/pagination", "knowme-client/mixins/controllers/email-integration", "ember-cli-file-saver/mixins/file-saver", "moment", "ember-uploader/uploaders", "lodash"], function (exports, _dateFilter, _queryable, _pagination, _emailIntegration, _fileSaver, _moment, _uploaders, _lodash) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var LeadsIndexController;

  LeadsIndexController = Ember.Controller.extend(_dateFilter.default, _queryable.default, _pagination.default, _fileSaver.default, _emailIntegration.default, {
    ajax: Ember.inject.service("authorized-ajax"),
    session: Ember.inject.service(),
    features: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    accountService: Ember.inject.service("account"),
    opportunityShowController: Ember.inject.controller("leads.show.opportunities.show"),
    applicationController: Ember.inject.controller("application"),
    queryParams: ["sort_by", "order", "on_date", "deal_id", "campaign_id", "ad_id", "filter", "converted", "converted_at", "user_id", "due_date", "sales_stage_id", "lead_id"], // queryParams that trigger model reload
    sort_by: null,
    order: null,
    on_date: null,
    deal_id: null,
    campaign_id: null,
    ad_id: null,
    filter: null,
    converted: null,
    converted_at: null,
    user_id: null,
    due_date: null,
    sales_stage_id: null,
    lead_id: null,
    summary: false,
    // collections
    users: [],
    stages: [],
    campaigns: [],
    ads: [],
    files: null,
    importLeadsModal: false,
    queryParamsForOpp: {
      action: null,
      contactId: null,
      contactEmailId: null,
      contactPhoneNumberId: null
    },
    _params: {},
    startConversationCommands: ["restart", "start"],
    onSearchByName: Ember.observer("query", function () {
      // reset all filter
      let query = this.get("query");
      let queryParams = this.get("queryParams");
      if (Ember.isPresent(query)) {
        // reset queryParams
        _lodash.default.each(queryParams, function (key) {
          if (!_lodash.default.includes(["query", "converted", "lead_id"], key) && Ember.isPresent(this.get(key))) {
            this.set(`_params.${key}`, this.get(key));
            if (key === "filter") {
              if (this.get("filter") !== "all_leads_and_customers") {
                this.set(key, "all");
              }
            } else {
              this.set(key, null);
            }
          }
        }.bind(this));
      } else {
        // restore queryParams
        _lodash.default.each(this.get("_params"), function (value, key) {
          this.set(key, value);
          this.set(`_params.${key}`, null);
        }.bind(this));
      }
    }),
    onDatePeriodSelected: Ember.observer("dateFilterSelected", function () {
      // reset current page
      this.set("page", null);
    }),
    // sorting: ['updatedAt:desc'],
    // sortedModel: Em.computed.sort('model', 'sorting'),
    sortedModel: Ember.computed.alias("model"),
    userHasKnowmeIQ: Ember.computed("session.currentUser.didAllocation.did.phoneNumber", function () {
      return Ember.isPresent(this.get("session.currentUser.didAllocation.did.phoneNumber"));
    }),
    isLeadMerging: Ember.computed("lead_id", function () {
      return Ember.isPresent(this.get("lead_id"));
    }),
    onMerging: Ember.observer("lead_id", function () {
      if (Ember.isPresent(this.get("lead_id"))) {
        this.set("applicationController.isLeadMerging", true);
        this.set("applicationController.leadId", this.get("lead_id"));
      } else {
        this.set("applicationController.isLeadMerging", null);
        this.set("applicationController.leadId", null);
      }
    }),
    afterPipelineOrRevenueColumnsCount: Ember.computed("isAssignedToVisible", "isPriorityVisible", "isDueDateVisible", "features.isBasicCrmOrKnowmeIQStarter", "features.emailIntegrationHasBeenCompleted", function () {
      let count = 5;
      if (this.get("features.isBasicCrmOrKnowmeIQStarter") && !this.get("features.emailIntegrationHasBeenCompleted")) {
        count = 4; // no actions column
      }
      if (!this.get("isAssignedToVisible")) {
        count--;
      }
      if (!this.get("isDueDateVisible")) {
        count--;
      }
      if (!this.get("isPriorityVisible")) {
        count--;
      }

      return count;
    }),
    visibleColumnsCount: Ember.computed("columns", "isPriorityVisible", "isPriorityEnabled", "isDueDateEnabled", "isDueDateVisible", "isAssignedToVisible", function () {
      let count = this.get("columns.length");
      if (this.get("isPriorityVisible") && !this.get("isPriorityEnabled")) {
        count++;
      }
      if (this.get("isDueDateVisible") && !this.get("isDueDateEnabled")) {
        count++;
      }
      if (!this.get("isAssignedToVisible")) {
        count--;
      }
      return count;
    }),
    columnsCount: Ember.computed("converted", function () {
      if (Ember.isPresent(this.get("converted"))) {
        return 10;
      } else {
        return 9;
      }
    }),
    isPriorityEnabled: Ember.computed("columns", "filter", function () {
      return Ember.isPresent(this.get("columns").filterBy("key", "priority"));
    }),
    isPriorityVisible: Ember.computed("isPriorityEnabled", function () {
      return this.get("isPriorityEnabled") || this.get("filter") === "high_priority";
    }),
    isDueDateEnabled: Ember.computed("columns", "filter", function () {
      return Ember.isPresent(this.get("columns").filterBy("key", "due_date"));
    }),
    isDueDateVisible: Ember.computed("isDueDateEnabled", function () {
      return this.get("isDueDateEnabled") || ["action_required", "past_due_action"].includes(this.get("filter"));
    }),
    isAssignedToVisible: Ember.computed("columns", "filter", function () {
      return Ember.isPresent(this.get("columns").filterBy("key", "assigned_to"));
    }),
    resetAdWhenCampaignChanged: Ember.observer("importCampaign.id", function () {
      this.set("importAd", null);
    }),
    adsImportOptions: Ember.computed("importCampaign", function () {
      if (this.get("importCampaign")) {
        return this.get("store").query("ad", {
          campaign_id: this.get("importCampaign.id"),
          capture_mode: "manual",
          paginate: false,
          sort_by: "name",
          order: "asc"
        });
      }
    }),
    actions: {
      showImportLeadsModal: function () {
        this.set("importLeadsModal", true);
        this.set("importCampaign", null);
        this.set("importAd", null);
        this.set("importUser", null);
        Ember.$(".import-leads input:file").val("");
        this.set("files", []);
      },
      hideImportLeadsModal: function () {
        this.set("importLeadsModal", false);
      },
      merge: function (resource, leadId) {
        resource.set("leadId", leadId);
        resource.save().then(function () {
          this.transitionToRoute("leads.show.opportunities", resource.get("id"));
        }.bind(this));
      },
      doCall: function (lead, opportunity, contactPhoneNumberId) {
        if (Ember.isPresent(opportunity)) {
          this.set("action", "call");
          this.transitionToRoute("leads.show.opportunities.show", lead.get("id"), opportunity.get("id"), {
            queryParams: _lodash.default.defaults({ contactPhoneNumberId: contactPhoneNumberId }, this.get("queryParamsForOpp"))
          });
        } else {
          this.transitionToRoute("leads.show.opportunities", lead.get("id"), {
            queryParams: this.get("queryParamsForOpp")
          });
        }
      },
      doSMS: function (lead, opportunity, contactPhoneNumberId) {
        if (Ember.isPresent(opportunity)) {
          this.set("action", "sms");
          this.transitionToRoute("leads.show.opportunities.show", lead.get("id"), opportunity.get("id"), {
            queryParams: _lodash.default.defaults({ contactPhoneNumberId: contactPhoneNumberId }, this.get("queryParamsForOpp"))
          });
        } else {
          this.transitionToRoute("leads.show.opportunities", lead.get("id"), {
            queryParams: this.get("queryParamsForOpp")
          });
        }
      },
      doEmail: function (lead, opportunity, contactEmailId) {
        if (Ember.isPresent(opportunity)) {
          this.set("action", "email");
          this.transitionToRoute("leads.show.opportunities.show", lead.get("id"), opportunity.get("id"), {
            queryParams: _lodash.default.defaults({ contactEmailId: contactEmailId }, this.get("queryParamsForOpp"))
          });
        } else {
          this.transitionToRoute("leads.show.opportunities", lead.get("id"), {
            queryParams: this.get("queryParamsForOpp")
          });
        }
      },
      openOpportunityPage: function (opportunity, contactId) {
        if (Ember.isPresent(contactId)) {
          this.transitionToRoute("leads.show.opportunities.show", opportunity.get("lead.id"), opportunity.get("id"), {
            queryParams: _lodash.default.defaults({ contactId: contactId }, this.get("queryParamsForOpp"))
          });
        } else {
          this.transitionToRoute("leads.show.opportunities.show", opportunity.get("lead.id"), opportunity.get("id"), { queryParams: this.get("queryParamsForOpp") });
        }
      },
      callTo: function () {},
      startListening: function () {
        this.send("pauseRefreshing");
      },
      stopListening: function () {
        this.send("continueRefreshing");
      },
      sort: function (toSortBy) {
        var sortBy = this.get("sort_by") || "name";
        var order = this.get("order");
        if (sortBy === toSortBy) {
          order = order === "asc" ? "desc" : "asc";
        } else {
          sortBy = toSortBy;
          order = "desc";
        }
        this.set("sort_by", sortBy);
        this.set("order", order);
        this.send("setOrder", sortBy, order);
      },
      toggleOpportunities: function (resource) {
        resource.set("opportunitiesDisplayed", !resource.get("opportunitiesDisplayed"));
      },
      upload: function (files) {
        let importUrlOptions = {};
        if (this.get("converted") === "1") {
          importUrlOptions.converted = 1;
        }
        if (Ember.isPresent(this.get("importAd"))) {
          importUrlOptions.ad_id = this.get("importAd.id");
        }
        if (Ember.isPresent(this.get("importUser"))) {
          importUrlOptions.assigned_to_id = this.get("importUser.id");
        }

        let url = Ember.getOwner(this).lookup("adapter:lead-import").buildURL("leads/import", null, null, null, importUrlOptions);
        const uploader = _uploaders.Uploader.create({ url: url });
        let ajaxSettings = { headers: this.get("ajax.headers") };
        uploader.set("ajaxSettings", ajaxSettings);
        uploader.upload(files[0]).then(() => {
          this.send("hideImportLeadsModal");
          // Handle success
          Ember.$("#import-leads").modal("hide");
          this.get("flashMessages").info("Your file is being processed.  Please check in your dashboard within a few minutes. You should be receiving a sumary e-mail shortly after your import is completed.", { destroyOnClick: true });
        }, error => {
          // Handle failure
          Ember.$("#import-leads").modal("hide");
          this.get("flashMessages").danger(error.responseJSON.error, {
            destroyOnClick: true
          });
        });
      },
      export: function () {
        let fileName = this.get("converted") ? `${this.get("accountService.customersText").toLowerCase()}.csv` : "leads.csv";
        let controller = this;
        let params = _lodash.default.merge(this.get("params"), {
          paginate: false,
          format: "csv",
          processData: false,
          dataType: "text"
        });

        let adapter = Ember.getOwner(this).lookup("adapter:lead");
        let url = adapter.buildURL("lead", null, null, "query", params);
        let query = [];
        for (let key in params) {
          if (Ember.isArray(params[key])) {
            params[key].forEach(function (value) {
              console.log(`${key}[]=${value}`);
              query.push(`${key}[]=${value}`);
            });
          } else {
            query.push(`${key}=${params[key]}`);
          }
        }
        let urlQuery = query.join("&");
        if (url.indexOf("?") < 0) {
          url = url + "?" + urlQuery;
        } else {
          url = url + "&" + urlQuery;
        }
        let csvRequest = controller.get("ajax").request(url, {
          method: "GET",
          processData: false,
          dataType: "blob",
          headers: controller.get("ajax.headers")
        });

        csvRequest.then(content => controller.saveFileAs(fileName, content, "csv"));
      }
    },
    dateFilterPromptText: Ember.computed("filter", function () {
      let text;
      switch (this.get("filter")) {
        case "action_required":
          text = "Filter by Due Date";
          break;
        case "appointments":
          text = "Filter by App. Date";
          break;
        case "past_due_action":
          text = "Filter by Due Date";
          break;
        case "converted":
          text = "Filter by Conversion Date";
          break;
        default:
          text = "Filter by Date";
      }
      return text;
    }),
    dateFilterOptions: Ember.computed("filter", function () {
      let options;
      switch (this.get("filter")) {
        case "appointments":
        case "action_required":
          options = [{ id: "today", name: "Today Only" }, { id: "todayAndNext6Days", name: "Today and Next 6 Days" }, { id: "todayAndNext29Days", name: "Today and Next 29 Days" }];
          break;
        case "past_due_action":
          options = [{ id: "previous3Days", name: "Previous 3 Days" }];
          break;
        default:
          options = [{ id: "today", name: "Today Only" }, { id: "todayAndYesterday", name: "Since Yesterday" }, { id: "todayAndPrevious2Days", name: "Last 3 Days" }, { id: "todayAndPrevious6Days", name: "Last 7 Days" }, { id: "todayAndPrevious29Days", name: "Last 30 Days" }, {
            id: "previousMonth",
            name: "Last Month: " + (0, _moment.default)().subtract(1, "months").format("MMMM YYYY")
          }, { id: "yearToDate", name: "Year to Date (YTD)" }, {
            id: "previousYear",
            name: "Last Year: " + (0, _moment.default)().subtract(1, "year").format("YYYY")
          }];
      }
      return options;
    }),
    campaignIdChanged: Ember.observer("campaign_id", function () {
      this.get("store").query("ad", {
        paginate: false,
        sort_by: "name",
        order: "asc",
        campaign_id: this.get("campaign_id")
      }).then(function (ads) {
        this.set("ads", ads);
        return ads;
      }.bind(this));
    }),
    campaign: Ember.computed("campaigns", "campaign_id", function () {
      return this.get("campaigns").findBy("id", this.get("campaign_id"));
    }),
    ad: Ember.computed("ads", "ad_id", function () {
      return this.get("ads").findBy("id", this.get("ad_id"));
    }),
    leadFilters: Ember.computed("model", "filterByAssignedToText", "accountService.customersText", function () {
      let session = this.get("session");
      let customersText = this.get("accountService.customersText");

      let filters = [{ id: "marketplace", name: "Marketplace" }, { id: "appointments", name: "Appointments" }, { id: "action_required", name: "To Do" }, { id: "past_due_action", name: "Past Due Opp." }, {
        id: "pipeline_opportunity",
        name: this.get("converted") ? "Pipeline Opp & Revenue" : "Leads with Pipeline"
      }, {
        id: "customers_and_leads_pipeline",
        name: `${customersText} and Leads Pipeline`
      }, { id: "high_priority", name: "High Priority Opp." }, {
        id: "pipeline_by_user",
        name: this.get("filterByUserPipelineToText")
      }, { id: "sales_stage", name: this.get("filterBySalesStage") }, {
        id: "assigned",
        name: this.get("converted") ? `${customersText} by All Users` : "Leads by All Users"
      }, { id: "assigned_to", name: this.get("filterByAssignedToText") }, { id: "assigned_by_sales_rep", name: "Assigned by Sales Rep" }, {
        id: "converted",
        name: this.get("converted") ? `${customersText} Revenue` : "Converted Leads"
      }, { id: "acquired_by_campaigns", name: "Automatically Captured" }, { id: "acquired_manually", name: "Manually Added" }, { id: "number_of_opportunities", name: "Number of Opp." }, { id: "acquired_today", name: "Generated Today" }, { id: "acquired_ytd", name: "Generated YTD" }, { id: "converted_today", name: "Converted Today" }, { id: "converted_ytd", name: "Converted YTD" }, {
        id: "active",
        name: this.get("converted") ? `Active ${customersText}` : "Active Leads"
      }, {
        id: "inactive",
        name: this.get("converted") ? `Inactive ${customersText}` : "Inactive Leads"
      }, { id: "unassigned", name: "Unassigned Leads" }, {
        id: "all",
        name: this.get("converted") ? `All ${customersText}` : "All Leads"
      }, {
        id: "all_leads_and_customers",
        name: `All Leads & ${customersText}`
      }];

      if (this.get("converted")) {
        filters.splice(3, 0, {
          id: "customers_pipeline_opportunity",
          name: `${customersText} Pipeline`
        });
      } else {
        filters.slice(4);
      }
      return filters;
    }),
    filtering: Ember.computed("dateFilterSelected", "campaign_id", "ad_id", "filter", function () {
      return Ember.isPresent(this.get("dateFilterSelected")) || Ember.isPresent(this.get("campaign_id")) || Ember.isPresent(this.get("ad_id")) || Ember.isPresent(this.get("filter"));
    }),
    pipelineTotal: Ember.computed("model.@each.pipeline", function () {
      return this.get("model").reduce(function (previousValue, currentItem) {
        return previousValue + currentItem.get("pipeline");
      }, 0);
    }),
    lostTotal: Ember.computed("model.@each.total", function () {
      return this.get("model").reduce(function (previousValue, currentItem) {
        return previousValue + currentItem.get("total");
      }, 0);
    }),
    revenueTotal: Ember.computed("model.@each.revenue", function () {
      return this.get("model").reduce(function (previousValue, currentItem) {
        return previousValue + currentItem.get("revenue");
      }, 0);
    }),
    totalOpportunitiesLost: Ember.computed("model.@each.opportunitiesLost", function () {
      return this.get("model").reduce(function (previousValue, currentItem) {
        return previousValue + currentItem.get("opportunitiesLost.length");
      }, 0);
    }),
    totalOpportunitiesConverted: Ember.computed("model.@each.opportunitiesConverted", function () {
      return this.get("model").reduce(function (previousValue, currentItem) {
        return previousValue + currentItem.get("opportunitiesConverted.length");
      }, 0);
    }),
    totalOpportunitiesNotConverted: Ember.computed("model.@each.opportunitiesNotConverted", function () {
      return this.get("model").reduce(function (previousValue, currentItem) {
        return previousValue + currentItem.get("opportunitiesNotConverted.length");
      }, 0);
    }),
    // totalOpportunitiesNotConvertedOrLost: Ember.computed('model.@each.opportunitiesNotConvertedOrLost', function(){
    //   return this.get('model').reduce(function(previousValue, currentItem) { return previousValue + currentItem.get('opportunitiesNotConvertedOrLost.length'); }, 0);
    // }),
    filterByUserPipelineToText: Ember.computed("model", "users.@each.name", "user_id", function () {
      if (this.get("session.currentUser.id") === this.get("user_id")) {
        return "My Pipeline";
      } else {
        let user = null;
        if (Ember.isPresent(this.get("user_id"))) {
          user = this.get("store").peekRecord("user", this.get("user_id"));
        }
        let name = Ember.isPresent(user) ? user.get("name") : "...";
        return `Pipeline by ${name}`;
      }
    }),
    filterByAssignedToText: Ember.computed("model", "users.@each.name", "user_id", "accountService.customersText", function () {
      let converted = this.get("converted");
      let customersText = this.get("accountService.customersText");
      let leadsOrCustomers = converted !== "1" ? "Leads" : customersText;
      if (this.get("session.currentUser.id") === this.get("user_id")) {
        return `My ${leadsOrCustomers}`;
      } else {
        let user = null;
        if (Ember.isPresent(this.get("user_id"))) {
          user = this.get("store").peekRecord("user", this.get("user_id"));
        }
        let name = Ember.isPresent(user) ? user.get("name") : "...";
        return `${leadsOrCustomers} by ${name}`;
      }
    }),
    filterBySalesStage: Ember.computed("model", "sales_stages.@each.name", "sales_stage_id", function () {
      let stage = null;
      if (Ember.isPresent(this.get("sales_stage_id"))) {
        stage = this.get("store").peekRecord("sales_stage", this.get("sales_stage_id"));
      }
      let name = Ember.isPresent(stage) ? stage.get("name") : "...";
      return `Sales stage: ${name}`;
    }),
    leads: Ember.computed.filter("model", function (resource) {
      return !resource.get("isConverted");
    }),
    customers: Ember.computed.filter("model", function (resource) {
      return resource.get("isConverted");
    }),
    onFilterChange: Ember.observer("filter", function () {
      let filter = this.get("filter");
      this.setProperties({ customFromDate: null, customToDate: null, page: 1 });
      if (filter === "action_required") {
        this.set("customFromDate", (0, _moment.default)().startOf("day").toDate());
      }
      if (filter === "past_due_action") {
        this.set("customToDate", (0, _moment.default)().subtract(1, "day").endOf("day").toDate());
      }
      if (filter === "converted_ytd") {
        this.set("dateFilterSelected", {
          id: "yearToDate",
          name: "Year to Date (YTD)"
        });
      }
    }),
    uploadFileButtonDisabled: Ember.computed("files.length", "importCampaign", "importAd", "importUser", function () {
      return Ember.isBlank(this.get("files")) || Ember.isBlank(this.get("importCampaign")) || Ember.isBlank(this.get("importAd")) || Ember.isBlank(this.get("importUser"));
    })
  });

  exports.default = LeadsIndexController;
});