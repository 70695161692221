define("knowme-client/routes/account/settings/business-profile/payment-method", ["exports", "knowme-client/mixins/routes/account-setup"], function (exports, _accountSetup) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    features: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    beforeModel: function (transition) {
      return this._super.apply(this, arguments).then(function () {
        if (transition.queryParams.payouts_enabled) {
          // when user completed stripe steps
          let stripeAccount = this.get("store").createRecord("stripe-account");
          stripeAccount.set("status", "active");
          return stripeAccount.save().then(function () {
            transition.abort();
            return this.transitionTo("account.index", {
              queryParams: { payouts_enabled: null }
            });
          }.bind(this));
        }
      }.bind(this));
    },
    //   model: function () {
    //     return this.get("session.currentUser")
    //       .then(function (currentUser) {
    //         return currentUser.get("account");
    //       })
    //       .then(function (account) {
    //         return account.reload();
    //       })
    //       .then(
    //         function (account) {
    //           if (!account.get("customerTypeConfirmed")) {
    //             account.set("customerType", null);
    //           }
    //           if (account.get("subscription.status") === "error") {
    //             let creditCard = this.get("store").createRecord("credit-card");
    //             account.set("creditCard", creditCard);
    //           }
    //           return account;
    //         }.bind(this)
    //       );
    //   },
    afterModel: function () {
      let promises = {
        stripeAccount: this.get("store").queryRecord("stripe-account", {})
      };

      return Ember.RSVP.hash(promises).then(function (results) {
        this.setProperties(results);
      }.bind(this));
    },
    setupController: function (controller) {
      this._super(...arguments); // Call _super for default behavior
      controller.stripeLoad();
      controller.init();
      controller.set("stripeAccount", this.get("stripeAccount"));

      let openPaymentIntegration = localStorage.getItem("openPaymentIntegration");
      localStorage.removeItem("openPaymentIntegration");
      if (Ember.isPresent(this.get("stripeAccount")) && !this.get("stripeAccount.payoutsEnabled") || openPaymentIntegration) {
        controller.set("isPaymentLinkSettingsOpened", true);
        this.get("flashMessages").warning("Payment Integration is not completed", { destroyOnClick: true, timeout: 12000 });
      }
      controller.set("paidInvoices", this.get("paidInvoices"));

      this.set("contractVersions", {});
      this.set("accountSummary", {});
      this.set("roles", []);
      this.set("dashboardWidgets", []);
    }
  });
});