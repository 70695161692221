define("knowme-client/mixins/products", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    products: [{
      key: "basic_crm",
      slug: "knowme_crm",
      description: "Add leads, be organized, and build better relationships with leads and customers",
      isPublic: true,
      isForPricing: true,
      settings: {
        basic: {
          crm: true,
          multipleDashboards: true,
          csvImport: true,
          dataMapping: true,
          companyAndContactInsights: true,
          contactManagment: true,
          salesFunnel: true,
          accountSettings: true,
          tasksOpportunitiesPipelineManagement: true,
          multipleOpportunitiesPipeline: true,
          revenueTracking: true,
          simpleCampaigns: true,
          reports: true,
          emailsIntegration: true,
          mobileApp: false,
          knowmeChat: true,
          emailTemplates: true,
          appointmentReminders: true
        }
      }
    }, {
      key: "sales_accelerator_crm",
      slug: "knowme_sales",
      description: "Capture leads, reduce manual entry, increase sales time, close more deals and accelerate sales",
      isPublic: true,
      settings: {
        premium: {
          isBestValue: true,
          crm: true,
          multipleDashboards: true,
          dialerAndTelesales: true,
          csvImport: true,
          dataMapping: true,
          emailsIntegration: true,
          callsIntegration: true,
          textsIntegration: true,
          companyAndContactInsights: true,
          contactManagment: true,
          salesFunnel: true,
          accountSettings: true,
          tasksOpportunitiesPipelineManagement: true,
          multipleOpportunitiesPipeline: true,
          automatedCommunicationTrackingPerOpportunity: true,
          smartNotifications: true,
          inboundCallRecording: true,
          leadsScoring: true,
          revenueTracking: true,
          simpleCampaigns: true,
          reports: true,
          outboundCallsPerUserReport: true,
          rolePermission: true,
          assignedSupportPerson: true
        },
        standard: {
          isMostPopular: true,

          crm: true,
          multipleDashboards: true,
          dialerAndTelesales: true,
          csvImport: true,
          dataMapping: true,
          emailsIntegration: true,
          callsIntegration: true,
          textsIntegration: true,
          companyAndContactInsights: true,
          contactManagment: true,
          salesFunnel: true,
          accountSettings: true,
          tasksOpportunitiesPipelineManagement: true,
          multipleOpportunitiesPipeline: true,
          automatedCommunicationTrackingPerOpportunity: true,
          smartNotifications: true,
          inboundCallRecording: true,
          leadsScoring: true,
          revenueTracking: true,
          simpleCampaigns: true,
          reports: true,
          outboundCallsPerUserReport: true,
          rolePermission: true,
          assignedSupportPerson: false
        },
        basic: {
          crm: true,
          multipleDashboards: true,
          dialerAndTelesales: true,
          csvImport: true,
          dataMapping: true,
          emailsIntegration: true,
          callsIntegration: true,
          textsIntegration: true,
          companyAndContactInsights: true,
          contactManagment: true,
          salesFunnel: true,
          accountSettings: true,
          tasksOpportunitiesPipelineManagement: true,
          multipleOpportunitiesPipeline: true,
          automatedCommunicationTrackingPerOpportunity: true,
          smartNotifications: true,
          inboundCallRecording: true,
          leadsScoring: true,
          revenueTracking: true,
          simpleCampaigns: true,
          reports: true,
          outboundCallsPerUserReport: false,
          rolePermission: false,
          assignedSupportPerson: false
        }
      }
    }, {
      key: "sales_accelerator_marketing_optimizer_crm",
      slug: "knowme_sales_marketing_and_feedback",
      description: "Capture all leads, market smarter, accelerate sales, sale more, save deals and get more referrals",
      isPublic: true,
      isBestValue: true,
      settings: {
        premium: {
          isBestValue: true,

          campaignsWithAutomatedLeadSource: true,
          attributionPerAd: true,
          dynamicInsertionNumbers: true,
          costLeadCalculation: true,
          inboundCallTrackingAndRecording: true,
          inboundTextTracking: true,
          roiCalculation: true,
          answeringServiceReplacement: true,
          emailLeadCapture: false,

          crm: true,
          multipleDashboards: true,
          dialerAndTelesales: true,
          csvImport: true,
          dataMapping: true,
          emailsIntegration: true,
          callsIntegration: true,
          textsIntegration: true,
          companyAndContactInsights: true,
          contactManagment: true,
          salesFunnel: true,
          accountSettings: true,
          tasksOpportunitiesPipelineManagement: true,
          multipleOpportunitiesPipeline: true,
          automatedCommunicationTrackingPerOpportunity: true,
          smartNotifications: true,
          inboundCallRecording: true,
          leadsScoring: true,
          revenueTracking: true,
          simpleCampaigns: true,
          reports: true,
          outboundCallsPerUserReport: true,
          reversCallerId: true,
          rolePermission: true,
          assignedSupportPerson: true,

          customersSatisfactionScore: true,
          instantUnhappyCustomers: true,
          instantHappyCustomers: true,
          improvementOrRegressionTracking: true,
          referralSourcesDiscovery: true,
          customersSatisfactionHistory: true,
          anonimousFeedbackSurvey: true
        },
        standard: {
          isMostPopular: true,

          campaignsWithAutomatedLeadSource: true,
          attributionPerAd: true,
          dynamicInsertionNumbers: true,
          costLeadCalculation: true,
          inboundCallTrackingAndRecording: true,
          inboundTextTracking: true,
          roiCalculation: true,
          answeringServiceReplacement: true,
          emailLeadCapture: false,

          crm: true,
          multipleDashboards: true,
          dialerAndTelesales: true,
          csvImport: true,
          dataMapping: true,
          emailsIntegration: true,
          callsIntegration: true,
          textsIntegration: true,
          companyAndContactInsights: true,
          contactManagment: true,
          salesFunnel: true,
          accountSettings: true,
          tasksOpportunitiesPipelineManagement: true,
          multipleOpportunitiesPipeline: true,
          automatedCommunicationTrackingPerOpportunity: true,
          smartNotifications: true,
          inboundCallRecording: true,
          leadsScoring: true,
          revenueTracking: true,
          simpleCampaigns: true,
          reports: true,
          outboundCallsPerUserReport: true,
          reversCallerId: true,
          rolePermission: true,
          assignedSupportPerson: false,

          customersSatisfactionScore: true,
          instantUnhappyCustomers: true,
          instantHappyCustomers: true,
          improvementOrRegressionTracking: true,
          referralSourcesDiscovery: true,
          customersSatisfactionHistory: true,
          anonimousFeedbackSurvey: true
        },
        basic: {
          campaignsWithAutomatedLeadSource: true,
          attributionPerAd: true,
          dynamicInsertionNumbers: true,
          costLeadCalculation: true,
          inboundCallTrackingAndRecording: true,
          inboundTextTracking: true,
          roiCalculation: true,
          answeringServiceReplacement: true,
          emailLeadCapture: false,

          crm: true,
          multipleDashboards: true,
          dialerAndTelesales: true,
          csvImport: true,
          dataMapping: true,
          emailsIntegration: true,
          callsIntegration: true,
          textsIntegration: true,
          companyAndContactInsights: true,
          contactManagment: true,
          salesFunnel: true,
          accountSettings: true,
          tasksOpportunitiesPipelineManagement: true,
          multipleOpportunitiesPipeline: true,
          automatedCommunicationTrackingPerOpportunity: true,
          smartNotifications: true,
          inboundCallRecording: true,
          leadsScoring: true,
          revenueTracking: true,
          simpleCampaigns: true,
          reports: true,
          outboundCallsPerUserReport: false,
          reversCallerId: false,
          rolePermission: false,
          assignedSupportPerson: false,

          customersSatisfactionScore: true,
          instantUnhappyCustomers: true,
          instantHappyCustomers: true,
          improvementOrRegressionTracking: true,
          referralSourcesDiscovery: true,
          customersSatisfactionHistory: true,
          anonimousFeedbackSurvey: true
        }
      }
    }, {
      key: "marketing_optimizer_crm",
      slug: "knowme_marketing",
      description: "Capture offline and online leads, see cost/lead for each source, market smarter and sell more",
      isPublic: true,
      settings: {
        premium: {
          isBestValue: true,

          campaignsWithAutomatedLeadSource: true,
          attributionPerAd: true,
          dynamicInsertionNumbers: true,
          costLeadCalculation: true,
          inboundCallTrackingAndRecording: true,
          inboundTextTracking: true,
          roiCalculation: true,
          answeringServiceReplacement: true,
          emailLeadCapture: true,

          crm: true,
          multipleDashboards: true,
          // telesales: true,
          // dialer: true,
          csvImport: true,
          dataMapping: true,
          // emailsIntegration: true,
          // callsIntegration: true,
          // textsIntegration:  true,
          companyAndContactInsights: true,
          contactManagment: true,
          salesFunnel: true,
          accountSettings: true,
          tasksOpportunitiesPipelineManagement: true,
          multipleOpportunitiesPipeline: true,
          // automatedCommunicationTrackingPerOpportunity: true,
          smartNotifications: true,
          // inboundCallRecording: true,
          leadsScoring: true,
          revenueTracking: true,
          // simpleCampaigns: true,
          reports: true,
          // outboundCallsPerUserReport: true,
          reversCallerId: true,
          rolePermission: true,
          assignedSupportPerson: true
        },
        standard: {
          isMostPopular: true,

          campaignsWithAutomatedLeadSource: true,
          attributionPerAd: true,
          dynamicInsertionNumbers: true,
          costLeadCalculation: true,
          inboundCallTrackingAndRecording: true,
          inboundTextTracking: true,
          roiCalculation: true,
          answeringServiceReplacement: true,
          emailLeadCapture: true,

          crm: true,
          multipleDashboards: true,
          // telesales: true,
          // dialer: true,
          csvImport: true,
          dataMapping: true,
          // emailsIntegration: true,
          // callsIntegration: true,
          // textsIntegration:  true,
          companyAndContactInsights: true,
          contactManagment: true,
          salesFunnel: true,
          accountSettings: true,
          tasksOpportunitiesPipelineManagement: true,
          multipleOpportunitiesPipeline: true,
          // automatedCommunicationTrackingPerOpportunity: true,
          smartNotifications: true,
          // inboundCallRecording: true,
          leadsScoring: true,
          revenueTracking: true,
          // simpleCampaigns: true,
          reports: true,
          // outboundCallsPerUserReport: true,
          reversCallerId: true,
          rolePermission: true,
          assignedSupportPerson: false
        },
        basic: {
          campaignsWithAutomatedLeadSource: true,
          attributionPerAd: true,
          dynamicInsertionNumbers: true,
          costLeadCalculation: true,
          inboundCallTrackingAndRecording: true,
          inboundTextTracking: true,
          roiCalculation: true,
          answeringServiceReplacement: true,
          emailLeadCapture: false,

          crm: true,
          multipleDashboards: true,
          // telesales: true,
          // dialer: true,
          csvImport: true,
          dataMapping: true,
          // emailsIntegration: true,
          // callsIntegration: true,
          // textsIntegration:  true,
          companyAndContactInsights: true,
          contactManagment: true,
          salesFunnel: true,
          accountSettings: true,
          tasksOpportunitiesPipelineManagement: true,
          multipleOpportunitiesPipeline: true,
          // automatedCommunicationTrackingPerOpportunity: true,
          smartNotifications: true,
          // inboundCallRecording: true,
          leadsScoring: true,
          revenueTracking: true,
          // simpleCampaigns: true,
          reports: true,
          // outboundCallsPerUserReport: true,
          reversCallerId: false,
          rolePermission: false,
          assignedSupportPerson: false
        }
      }
    }, {
      key: "customer_satisfaction",
      slug: "knowme_feedback",
      description: "Get real-time feedback, uncover unhappy customers, save deals and get more referrals",
      isPublic: true,
      settings: {
        premium: {
          isBestValue: true,

          customersSatisfactionScore: true,
          instantUnhappyCustomers: true,
          instantHappyCustomers: true,
          improvementOrRegressionTracking: true,
          referralSourcesDiscovery: true,
          customersSatisfactionHistory: true,
          anonimousFeedbackSurvey: true,
          customersFeedbackViaText: true,
          dealRecoveryTracking: true,

          campaignsWithAutomatedLeadSource: true,
          attributionPerAd: true,
          dynamicInsertionNumbers: true,
          costLeadCalculation: true,
          inboundCallTrackingAndRecording: true,
          inboundTextTracking: true,
          roiCalculation: true,
          answeringServiceReplacement: true,
          emailLeadCapture: true,

          crm: true,
          multipleDashboards: true,
          // telesales: true,
          // dialer: true,
          csvImport: true,
          dataMapping: true,
          emailsIntegration: true,
          callsIntegration: true,
          textsIntegration: true,
          companyAndContactInsights: true,
          contactManagment: true,
          // salesFunnel: true,
          accountSettings: true,
          tasksOpportunitiesPipelineManagement: true,
          multipleOpportunitiesPipeline: true,
          // automatedCommunicationTrackingPerOpportunity: true,
          smartNotifications: true,
          inboundCallRecording: true,
          // leadsScoring: true,
          // revenueTracking: true,
          // simpleCampaigns: true,
          reports: true,
          // outboundCallsPerUserReport: true,
          // reversCallerId: false,
          rolePermission: true
          // assignedSupportPerson: false,
        },
        standard: {
          isMostPopular: true,

          customersSatisfactionScore: true,
          instantUnhappyCustomers: true,
          instantHappyCustomers: true,
          improvementOrRegressionTracking: true,
          referralSourcesDiscovery: true,
          customersSatisfactionHistory: true,
          anonimousFeedbackSurvey: true,
          customersFeedbackViaText: true,
          dealRecoveryTracking: true,

          campaignsWithAutomatedLeadSource: true,
          attributionPerAd: true,
          dynamicInsertionNumbers: true,
          costLeadCalculation: true,
          inboundCallTrackingAndRecording: true,
          inboundTextTracking: true,
          roiCalculation: true,
          answeringServiceReplacement: true,
          emailLeadCapture: true,

          crm: true,
          multipleDashboards: true,
          // telesales: true,
          // dialer: true,
          csvImport: true,
          dataMapping: true,
          emailsIntegration: true,
          callsIntegration: true,
          textsIntegration: true,
          companyAndContactInsights: true,
          contactManagment: true,
          // salesFunnel: true,
          accountSettings: true,
          tasksOpportunitiesPipelineManagement: true,
          multipleOpportunitiesPipeline: true,
          // automatedCommunicationTrackingPerOpportunity: true,
          smartNotifications: true,
          inboundCallRecording: true,
          // leadsScoring: true,
          // revenueTracking: true,
          // simpleCampaigns: true,
          reports: true,
          // outboundCallsPerUserReport: true,
          // reversCallerId: false,
          rolePermission: true
          // assignedSupportPerson: false,
        },
        basic: {
          customersSatisfactionScore: true,
          instantUnhappyCustomers: true,
          instantHappyCustomers: true,
          improvementOrRegressionTracking: true,
          referralSourcesDiscovery: true,
          customersSatisfactionHistory: true,
          anonimousFeedbackSurvey: true,
          customersFeedbackViaText: true,
          dealRecoveryTracking: true,

          campaignsWithAutomatedLeadSource: true,
          attributionPerAd: true,
          dynamicInsertionNumbers: true,
          costLeadCalculation: true,
          inboundCallTrackingAndRecording: true,
          inboundTextTracking: true,
          roiCalculation: true,
          answeringServiceReplacement: true,
          emailLeadCapture: false,

          crm: true,
          multipleDashboards: true,
          // telesales: true,
          // dialer: true,
          csvImport: true,
          dataMapping: true,
          emailsIntegration: true,
          callsIntegration: true,
          textsIntegration: true,
          companyAndContactInsights: true,
          contactManagment: true,
          // salesFunnel: true,
          accountSettings: true,
          tasksOpportunitiesPipelineManagement: true,
          multipleOpportunitiesPipeline: true,
          // automatedCommunicationTrackingPerOpportunity: true,
          smartNotifications: true,
          inboundCallRecording: true,
          // leadsScoring: true,
          // revenueTracking: true,
          // simpleCampaigns: true,
          reports: true,
          // outboundCallsPerUserReport: true,
          // reversCallerId: false,
          rolePermission: false
          // assignedSupportPerson: false
        }
      }
    }, {
      key: "knowme_iq",
      slug: "knowme_iq",
      description: "Capture all leads, market smarter, accelerate sales, sale more, save deals and get more referrals",
      isPublic: false,
      isForPricing: true,
      isBestValue: true,
      settings: {
        // starter: {
        //   description:
        //     "Manage leads and customers and collaborate with your team through the Chat (Calling and Texting excluded).",
        //   unlimited: false,

        //   crm: true,
        //   multipleDashboards: true,
        //   csvImport: true,
        //   dataMapping: true,
        //   companyAndContactInsights: true,
        //   contactManagment: true,
        //   salesFunnel: true,
        //   accountSettings: true,
        //   quotesAndInvoices: true,
        //   tasksOpportunitiesPipelineManagement: true,
        //   multipleOpportunitiesPipeline: true,
        //   revenueTracking: true,
        //   simpleCampaigns: true,
        //   reports: true,
        //   emailsIntegration: true,
        //   mobileApp: true,
        //   knowmeChat: true,
        //   webChat: true,
        //   emailTemplates: true,
        //   appointmentReminders: true,
        //   quickbooksIntegration: false,

        //   notificationsAndAlerts: true,

        //   webformLeadCapture: true,
        //   messageHistory: "Unlmited",

        //   customerEmailFeedback: true,
        //   unlimitedUsers: false,
        // },
        // starter_unlimited: {
        //   description:
        //     "Manage leads and customers and collaborate with your team through the Chat (Calling and Texting excluded).",
        //   unlimited: true,

        //   crm: true,
        //   multipleDashboards: true,
        //   csvImport: true,
        //   dataMapping: true,
        //   companyAndContactInsights: true,
        //   contactManagment: true,
        //   salesFunnel: true,
        //   accountSettings: true,
        //   quotesAndInvoices: true,
        //   tasksOpportunitiesPipelineManagement: true,
        //   multipleOpportunitiesPipeline: true,
        //   revenueTracking: true,
        //   simpleCampaigns: true,
        //   reports: true,
        //   emailsIntegration: true,
        //   mobileApp: true,
        //   knowmeChat: true,
        //   webChat: true,
        //   emailTemplates: true,
        //   appointmentReminders: true,
        //   quickbooksIntegration: true,

        //   notificationsAndAlerts: true,

        //   webformLeadCapture: true,
        //   messageHistory: "Unlmited",

        //   customerEmailFeedback: true,
        //   unlimitedUsers: true,
        // },
        premium: {
          description: "<p>Work from anywhere, anytime - collaborate with your team, engage and manage leads and customers on any channel, in Knowme IQ.</p><p> Market smarter, automate lead capture, accelerate sales,provide superior customer experience and get more referrals.</p>",
          unlimited: false,

          textMessagingPlatform: true,
          crm: true,
          customerExperience: true,
          callTracking: true,
          mobileApp: true,
          knowmeChat: true,
          webChat: true,
          emailTemplates: true,
          appointmentReminders: true,

          quickbooksIntegration: true,

          notificationsAndAlerts: true,

          csvImport: true,
          multipleDashboards: true,
          tasksOpportunitiesPipelineManagement: true,
          multipleOpportunitiesPipeline: true,
          appointmentsToDoAndPastDueActivities: true,
          automatedCommunicationTrackingPerOpportunity: true,
          instantCustomersSatisfactionNotifications: true,
          automatedLeadCapture: true,
          revenueTracking: true,
          dialerAndTelesales: true,
          emailsIntegration: true,
          callsIntegration: true,
          textsIntegration: true,
          inboundCallTrackingAndRecording: true,
          companyAndContactInsights: true,
          dataMapping: true,
          accountSettings: true,
          quotesAndInvoices: true,
          leadsScoring: true,
          contactManagment: true,
          reports: true,

          includedCallTouchPointsMultiRing: true,
          provisionAndPurchasePhoneNumbers: true,

          campaignsWithAutomatedLeadSource: true,
          attributionPerAd: true,
          dynamicInsertionNumbers: true,
          costLeadCalculation: true,
          inboundTextTracking: true,
          roiCalculation: true,
          answeringServiceReplacement: true,
          emailLeadCapture: false,

          salesFunnel: true,
          smartNotifications: true,
          inboundCallRecording: true,

          simpleCampaigns: true,
          outboundCallsPerUserReport: false,
          reversCallerId: false,
          rolePermission: false,
          assignedSupportPerson: false,

          messageHistory: "Unlmited",

          webformLeadCapture: true,
          customerEmailFeedback: true,
          automatedLeadCaptureViaIncentive: true,
          reviewsBooster: true,
          customersSatisfactionScore: true,
          instantUnhappyCustomers: true,
          instantHappyCustomers: true,
          improvementOrRegressionTracking: true,
          referralSourcesDiscovery: true,
          customersSatisfactionHistory: true,
          anonimousFeedbackSurvey: true,
          unlimitedUsers: false
        },
        premium_unlimited: {
          description: "<p>Work from anywhere, anytime - collaborate with your team, engage and manage leads and customers on any channel, in Knowme IQ.</p><p> Market smarter, automate lead capture, accelerate sales,provide superior customer experience and get more referrals.</p>",
          unlimited: true,

          textMessagingPlatform: true,
          crm: true,
          customerExperience: true,
          callTracking: true,
          mobileApp: true,
          knowmeChat: true,
          webChat: true,
          emailTemplates: true,
          appointmentReminders: true,

          quickbooksIntegration: true,

          notificationsAndAlerts: true,

          csvImport: true,
          multipleDashboards: true,
          tasksOpportunitiesPipelineManagement: true,
          multipleOpportunitiesPipeline: true,
          appointmentsToDoAndPastDueActivities: true,
          automatedCommunicationTrackingPerOpportunity: true,
          instantCustomersSatisfactionNotifications: true,
          automatedLeadCapture: true,
          revenueTracking: true,
          dialerAndTelesales: true,
          emailsIntegration: true,
          callsIntegration: true,
          textsIntegration: true,
          inboundCallTrackingAndRecording: true,
          companyAndContactInsights: true,
          dataMapping: true,
          accountSettings: true,
          quotesAndInvoices: true,
          leadsScoring: true,
          contactManagment: true,
          reports: true,

          includedCallTouchPointsMultiRing: true,
          provisionAndPurchasePhoneNumbers: true,

          campaignsWithAutomatedLeadSource: true,
          attributionPerAd: true,
          dynamicInsertionNumbers: true,
          costLeadCalculation: true,
          inboundTextTracking: true,
          roiCalculation: true,
          answeringServiceReplacement: true,
          emailLeadCapture: false,

          salesFunnel: true,
          smartNotifications: true,
          inboundCallRecording: true,

          simpleCampaigns: true,
          outboundCallsPerUserReport: false,
          reversCallerId: false,
          rolePermission: false,
          assignedSupportPerson: false,

          messageHistory: "Unlmited",

          webformLeadCapture: true,
          customerEmailFeedback: true,
          automatedLeadCaptureViaIncentive: true,
          reviewsBooster: true,
          customersSatisfactionScore: true,
          instantUnhappyCustomers: true,
          instantHappyCustomers: true,
          improvementOrRegressionTracking: true,
          referralSourcesDiscovery: true,
          customersSatisfactionHistory: true,
          anonimousFeedbackSurvey: true,

          unlimitedUsers: true
        },
        autopylot: {
          description: "<p>Work from anywhere, anytime - collaborate with your team, engage and manage leads and customers on any channel, in Knowme IQ.</p><p> Market smarter, automate lead capture, accelerate sales,provide superior customer experience and get more referrals.</p>",
          unlimited: false,

          textMessagingPlatform: true,
          crm: true,
          customerExperience: true,
          callTracking: true,
          mobileApp: true,
          knowmeChat: true,
          webChat: true,
          emailTemplates: true,
          appointmentReminders: true,

          quickbooksIntegration: true,

          notificationsAndAlerts: true,

          csvImport: true,
          multipleDashboards: true,
          tasksOpportunitiesPipelineManagement: true,
          multipleOpportunitiesPipeline: true,
          appointmentsToDoAndPastDueActivities: true,
          automatedCommunicationTrackingPerOpportunity: true,
          instantCustomersSatisfactionNotifications: true,
          automatedLeadCapture: true,
          revenueTracking: true,
          dialerAndTelesales: true,
          emailsIntegration: true,
          callsIntegration: true,
          textsIntegration: true,
          inboundCallTrackingAndRecording: true,
          companyAndContactInsights: true,
          dataMapping: true,
          accountSettings: true,
          quotesAndInvoices: true,
          leadsScoring: true,
          contactManagment: true,
          reports: true,

          includedCallTouchPointsMultiRing: true,
          provisionAndPurchasePhoneNumbers: true,

          campaignsWithAutomatedLeadSource: true,
          attributionPerAd: true,
          dynamicInsertionNumbers: true,
          costLeadCalculation: true,
          inboundTextTracking: true,
          roiCalculation: true,
          answeringServiceReplacement: true,
          emailLeadCapture: false,

          salesFunnel: true,
          smartNotifications: true,
          inboundCallRecording: true,

          simpleCampaigns: true,
          outboundCallsPerUserReport: false,
          reversCallerId: false,
          rolePermission: false,
          assignedSupportPerson: false,

          messageHistory: "Unlmited",

          webformLeadCapture: true,
          customerEmailFeedback: true,
          automatedLeadCaptureViaIncentive: true,
          reviewsBooster: true,
          customersSatisfactionScore: true,
          instantUnhappyCustomers: true,
          instantHappyCustomers: true,
          improvementOrRegressionTracking: true,
          referralSourcesDiscovery: true,
          customersSatisfactionHistory: true,
          anonimousFeedbackSurvey: true,
          unlimitedUsers: false
        },
        autopylot_unlimited: {
          description: "<p>Work from anywhere, anytime - collaborate with your team, engage and manage leads and customers on any channel, in Knowme IQ.</p><p> Market smarter, automate lead capture, accelerate sales,provide superior customer experience and get more referrals.</p>",
          unlimited: true,

          textMessagingPlatform: true,
          crm: true,
          customerExperience: true,
          callTracking: true,
          mobileApp: true,
          knowmeChat: true,
          webChat: true,
          emailTemplates: true,
          appointmentReminders: true,

          quickbooksIntegration: true,

          notificationsAndAlerts: true,

          csvImport: true,
          multipleDashboards: true,
          tasksOpportunitiesPipelineManagement: true,
          multipleOpportunitiesPipeline: true,
          appointmentsToDoAndPastDueActivities: true,
          automatedCommunicationTrackingPerOpportunity: true,
          instantCustomersSatisfactionNotifications: true,
          automatedLeadCapture: true,
          revenueTracking: true,
          dialerAndTelesales: true,
          emailsIntegration: true,
          callsIntegration: true,
          textsIntegration: true,
          inboundCallTrackingAndRecording: true,
          companyAndContactInsights: true,
          dataMapping: true,
          accountSettings: true,
          quotesAndInvoices: true,
          leadsScoring: true,
          contactManagment: true,
          reports: true,

          includedCallTouchPointsMultiRing: true,
          provisionAndPurchasePhoneNumbers: true,

          campaignsWithAutomatedLeadSource: true,
          attributionPerAd: true,
          dynamicInsertionNumbers: true,
          costLeadCalculation: true,
          inboundTextTracking: true,
          roiCalculation: true,
          answeringServiceReplacement: true,
          emailLeadCapture: false,

          salesFunnel: true,
          smartNotifications: true,
          inboundCallRecording: true,

          simpleCampaigns: true,
          outboundCallsPerUserReport: false,
          reversCallerId: false,
          rolePermission: false,
          assignedSupportPerson: false,

          messageHistory: "Unlmited",

          webformLeadCapture: true,
          customerEmailFeedback: true,
          automatedLeadCaptureViaIncentive: true,
          reviewsBooster: true,
          customersSatisfactionScore: true,
          instantUnhappyCustomers: true,
          instantHappyCustomers: true,
          improvementOrRegressionTracking: true,
          referralSourcesDiscovery: true,
          customersSatisfactionHistory: true,
          anonimousFeedbackSurvey: true,

          unlimitedUsers: true
        }
        // elite: {
        //   description: '<p>Work from anywhere, anytime - collaborate with your team, engage and manage leads and customers on any channel, in Knowme IQ.</p><p> Market smarter, automate lead capture, accelerate sales,provide superior customer experience and get more referrals.</p>',
        //
        //   textMessagingPlatform: true,
        //   crm: true,
        //   customerExperience: true,
        //   callTracking: true,
        //   mobileApp: true,
        //   knowmeChat: true,
        //   webChat: true,
        //   emailTemplates: true,
        //   appointmentReminders: true,
        //
        //   quickbooksIntegration: true,
        //
        //   notificationsAndAlerts: true,
        //
        //   csvImport: true,
        //   multipleDashboards: true,
        //   tasksOpportunitiesPipelineManagement: true,
        //   multipleOpportunitiesPipeline: true,
        //   appointmentsToDoAndPastDueActivities: true,
        //   automatedCommunicationTrackingPerOpportunity: true,
        //   instantCustomersSatisfactionNotifications: true,
        //   automatedLeadCapture: true,
        //   revenueTracking: true,
        //   dialerAndTelesales: true,
        //   emailsIntegration: true,
        //   callsIntegration: true,
        //   textsIntegration:  true,
        //   inboundCallTrackingAndRecording: true,
        //   companyAndContactInsights: true,
        //   dataMapping: true,
        //   accountSettings: true,
        //   leadsScoring: true,
        //   contactManagment: true,
        //   reports: true,
        //
        //   includedCallTouchPointsMultiRing: true,
        //   provisionAndPurchasePhoneNumbers: true,
        //
        //   campaignsWithAutomatedLeadSource: true,
        //   attributionPerAd: true,
        //   dynamicInsertionNumbers: true,
        //   costLeadCalculation: true,
        //   inboundTextTracking: true,
        //   roiCalculation: true,
        //   answeringServiceReplacement: true,
        //   emailLeadCapture: false,
        //
        //   salesFunnel: true,
        //   smartNotifications: true,
        //   inboundCallRecording: true,
        //
        //   simpleCampaigns: true,
        //   outboundCallsPerUserReport: false,
        //   reversCallerId: false,
        //   rolePermission: false,
        //   assignedSupportPerson: false,
        //
        //   webformLeadCapture: true,
        //   customerEmailFeedback: true,
        //   automatedLeadCaptureViaIncentive: true,
        //   reviewsBooster: true,
        //   customersSatisfactionScore: true,
        //   instantUnhappyCustomers: true,
        //   instantHappyCustomers: true,
        //   improvementOrRegressionTracking: true,
        //   referralSourcesDiscovery: true,
        //   customersSatisfactionHistory: true,
        //   anonimousFeedbackSurvey: true
        // }
      }
    }]
  });
});