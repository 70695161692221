define("knowme-client/controllers/account/settings/business-profile/payment-method", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    router: Ember.inject.service(),
    stripe: Ember.inject.service("stripe"),
    businessProfileController: Ember.inject.controller("account.settings.business-profile"),
    model: Ember.computed.alias("businessProfileController.model"),
    isAddPaymentPressed: false,
    stripeLoad: function () {
      this.get("stripe").load();
    },
    init: function () {
      this._super(...arguments);
      this.set("isAddPaymentPressed", false);
    },
    actions: {
      addPaymentMethod: function () {
        this.set("isAddPaymentPressed", true);
        let stripeAccount = this.get("store").createRecord("stripe-account");
        stripeAccount.save().then(function (sa) {
          window.open(sa.get("accountLink"), "_self").focus();
        });
      },
      back: function () {
        history.back();
      },
      submit: function () {
        this.get("router").transitionTo("account.settings.business-profile.address");
      }
    }
  });
});