define("knowme-client/routes/account/users/edit", ["exports", "knowme-client/mixins/routes/account-setup"], function (exports, _accountSetup) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    session: Ember.inject.service(),
    model: function (params) {
      return this.get("store").find("user", params.id);
    },
    afterModel: function (model) {
      model.get("didAllocation").then(function (didAllocation) {
        if (Ember.isBlank(didAllocation)) {
          console.log("Initiating new allocation");
          this.store.createRecord("did-allocation", { allocatable: model });
        }
      }.bind(this));

      if (this.get("session.currentUser.hasAdminRole") || this.get("session.currentUser.isManager")) {
        return Ember.RSVP.hash({
          roles: this.get("store").query("role", {
            paginate: false,
            sort_by: "name",
            order: "asc"
          })
        }).then(function (results) {
          this.set("controllerData", results);
        }.bind(this));
      }
    },
    setupController: function (controller) {
      // Call _super for default behavior
      this._super(...arguments);
      controller.setProperties(this.get("controllerData"));
      this.set("controllerData", {});
    },
    resetController: function (controller, isExiting /*, transition*/) {
      if (isExiting) {
        controller.get("model").rollbackAttributes();
        let originalRoleId = controller.get("model").get("_internalModel._relationships").get("roles").canonicalState[0].id;
        let originalRole = controller.get("store").peekRecord("role", originalRoleId);
        controller.get("model").set("role", originalRole);
        let didAllocation = controller.get("model.didAllocation");
        if (didAllocation.get("isNew")) {
          didAllocation.set("didNumber", "");
        }
      }
    },
    actions: {
      willTransition: function () /*transition*/{
        let model = this.controller.get("model");
        model.rollbackAttributes();
      }
      // didTransition: function (transition) {
      //   // console.log("didTransition", transition);
      //   console.log("didTransition");
      //   this.refresh();
      // },
    }
  });
});