define("knowme-client/routes/account/settings/deals", ["exports", "knowme-client/mixins/routes/account-setup"], function (exports, _accountSetup) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    features: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    pagination: {},
    queryParams: {
      status: {
        refreshModel: true
      },
      query: {
        refreshModel: true
      },
      filter: {
        refreshModel: true
      }
    },
    model: function (params) {
      let promise = null;
      promise = this.get("store").query("deal", params);

      promise.then(result => {
        this.set("pagination.total", result.meta.pagination.totalCount);
        this.set("pagination.limit", result.meta.pagination.page.size);
        this.set("pagination.page", result.meta.pagination.page.number);

        this.set("selectedResource", null);
      });
      return promise;
    },
    afterModel: function (model) {
      this.set("controllerData", {
        account: this.get("features.account")
      });
    },
    setupController: function (controller) {
      this._super.apply(this, arguments);
      // Model is already set, so we have to explicitly update controller
      controller.setProperties(this.get("controllerData"));
      this.set("controllerData", {});

      controller.set("selectedResource", this.get("selectedResource"));
      controller.set("total", this.get("pagination.total"));
      controller.set("limit", this.get("pagination.limit"));
      controller.set("count", Math.ceil(this.get("pagination.total") / this.get("pagination.limit")));
      controller.set("current", this.get("pagination.page"));
    },
    actions: {
      reloadModel: function () {
        this.refresh();
      },
      queryParamsDidChange: function () {
        this.refresh();
      }
    }
  });
});