define("knowme-client/routes/forgot-password", ["exports", "knowme-client/mixins/routes/unauthenticated-mixin"], function (exports, _unauthenticatedMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let ForgotPasswordRoute;

  ForgotPasswordRoute = Ember.Route.extend(_unauthenticatedMixin.default);

  exports.default = ForgotPasswordRoute;
});