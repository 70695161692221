define("knowme-client/helpers/consent-status", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    compute([contactPhoneNumber, did]) {
      if (!did) {
        return;
      }
      return this.get("store").query("texting-permission", {
        contact_phone_number_id: contactPhoneNumber.get("id"),
        did_id: did.get("id")
      });
    }
  });
});