define("knowme-client/models/business-profile", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "knowme-client/mixins/models/validations/business-profile", "knowme-client/mixins/models/ember-cp-validations-custom-properties", "knowme-client/mixins/dirty"], function (exports, _model, _attr, _relationships, _businessProfile, _emberCpValidationsCustomProperties, _dirty) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var BusinessProfile;

  const WORK_TIMES_FIELDS = ["workTimes.mon.open", "workTimes.mon.from.h", "workTimes.mon.from.m", "workTimes.mon.from.ampm", "workTimes.mon.to.h", "workTimes.mon.to.m", "workTimes.mon.to.ampm", "workTimes.tue.open", "workTimes.tue.from.h", "workTimes.tue.from.m", "workTimes.tue.from.ampm", "workTimes.tue.to.h", "workTimes.tue.to.m", "workTimes.tue.to.ampm", "workTimes.wed.open", "workTimes.wed.from.h", "workTimes.wed.from.m", "workTimes.wed.from.ampm", "workTimes.wed.to.h", "workTimes.wed.to.m", "workTimes.wed.to.ampm", "workTimes.thu.open", "workTimes.thu.from.h", "workTimes.thu.from.m", "workTimes.thu.from.ampm", "workTimes.thu.to.h", "workTimes.thu.to.m", "workTimes.thu.to.ampm", "workTimes.fri.open", "workTimes.fri.from.h", "workTimes.fri.from.m", "workTimes.fri.from.ampm", "workTimes.fri.to.h", "workTimes.fri.to.m", "workTimes.fri.to.ampm", "workTimes.sat.open", "workTimes.sat.from.h", "workTimes.sat.from.m", "workTimes.sat.from.ampm", "workTimes.sat.to.h", "workTimes.sat.to.m", "workTimes.sat.to.ampm", "workTimes.sun.open", "workTimes.sun.from.h", "workTimes.sun.from.m", "workTimes.sun.from.ampm", "workTimes.sun.to.h", "workTimes.sun.to.m", "workTimes.sun.to.ampm"];

  BusinessProfile = _model.default.extend(_businessProfile.default, _emberCpValidationsCustomProperties.default, _dirty.default, {
    // Relationships
    account: (0, _relationships.belongsTo)("account", { async: true }),
    // Attributes
    logoUrl: (0, _attr.default)("string"),
    logoThumbnailUrl: (0, _attr.default)("string"),
    logoSmallUrl: (0, _attr.default)("string"),
    logo: (0, _attr.default)(),
    name: (0, _attr.default)(),
    email: (0, _attr.default)(),
    website: (0, _attr.default)(),
    category: (0, _attr.default)(),
    phoneNumber: (0, _attr.default)(),
    addressCountry: (0, _attr.default)(),
    addressCity: (0, _attr.default)(),
    addressState: (0, _attr.default)(),
    addressStreet: (0, _attr.default)(),
    workTimes: (0, _attr.default)(),
    setupHasChangedWorkTimes: Ember.on("init", function () {
      let workTimes = Ember.Object.create(Ember.copy(this.get("workTimes"), true));
      Ember.defineProperty(this, "hasWorkTimesChanged", Ember.computed(...WORK_TIMES_FIELDS, function () {
        let hasChanged = false;
        WORK_TIMES_FIELDS.forEach(function (field) {
          let parts = field.split(".");
          parts.shift();
          let key = parts.join(".");
          if (this.get(field) != workTimes.get(key)) {
            console.log(field, this.get(field), workTimes.get(key));
          }
          hasChanged = hasChanged || this.get(field) != workTimes.get(key);
        }.bind(this));
        return hasChanged;
      }));
    }),
    createdAt: (0, _attr.default)("date"),
    updatedAt: (0, _attr.default)("date")
  });

  exports.default = BusinessProfile;
});