define("knowme-client/routes/leads/show/opportunities/show", ["exports", "knowme-client/mixins/routes/account-setup", "knowme-client/mixins/routes/cable-setup", "knowme-client/mixins/routes/contact-phone-number-subscriber", "ember-local-storage"], function (exports, _accountSetup, _cableSetup, _contactPhoneNumberSubscriber, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let LeadsShowOpportunitiesShowRoute;

  LeadsShowOpportunitiesShowRoute = Ember.Route.extend(_accountSetup.default, _cableSetup.default, _contactPhoneNumberSubscriber.default, {
    session: Ember.inject.service("session"),
    store: Ember.inject.service(),
    lastOpportunity: (0, _emberLocalStorage.storageFor)("last-opportunity"),
    channelName: "ActivityChannel",
    queryParams: {
      contactPhoneNumberId: { refreshModel: true },
      contactEmailId: { refreshModel: true },
      action: { refreshModel: true }
    },
    model: function (params) {
      let lead = this.modelFor("leads.show");
      this.set("lead", lead);
      let opportunity = this.get("store").find("opportunity", params.opportunity_id);
      this.set("lastOpportunity.id", params.opportunity_id);
      if (Ember.isPresent(params.contactId)) {
        this.set("selectedContact", lead.get("contacts").findBy("id", params.contactId));
      }

      return opportunity;
    },
    afterModel: function (model) {
      this.set("subscriptionData", { id: model.get("id") });
      return Ember.RSVP.hash({
        incentives: this.get("store").query("incentive", {
          paginate: false,
          status: "active"
        }),
        reviewLinks: this.get("store").query("review-link", {
          paginate: false,
          status: "active"
        }),
        salesTransactions: model.get("salesTransactions"),
        lastSalesTransaction: model.belongsTo("lastSalesTransaction").reload(),
        opportunityFields: this.get("store").query("opportunity-field", {
          enable: true
        }),
        soldOutProducts: this.get("store").query("product", {
          paginate: false,
          sort_by: "name",
          order: "asc",
          status: "sold-out"
        }),
        products: this.get("store").query("product", {
          paginate: false,
          sort_by: "name",
          order: "asc",
          status: "active"
        }),
        salesActions: this.get("store").query("sales-action", {
          paginate: false,
          status: "active"
        }),
        salesStages: this.get("store").query("sales-stage", {
          paginate: false,
          status: "active"
        }),
        ad: model.get("ad"),
        allUsers: this.get("store").query("user", {
          paginate: false,
          status: "active",
          sort_by: "name",
          order: "asc"
        }),
        filledNoteActivities: this.get("store").query("lead-activity", {
          paginate: false,
          filter: "filled_notes",
          opportunity_id: model.id
        }),
        users: this.get("store").query("user", {
          paginate: false,
          status: "active",
          sort_by: "name",
          order: "asc"
        }),
        activities: this.get("store").query("lead-activity", {
          paginate: false,
          opportunity_id: model.id
        }),
        did: this.get("session.currentUser.didAllocation.did")
      }).then(function (results) {
        this.set("controllerData", results);
      }.bind(this));
    },
    setupController: function (controller) {
      // Call _super for default behavior
      controller.set("isRefreshingForm", true);
      this._super(...arguments);
      controller.setProperties(this.get("controllerData"));
      controller.set("campaigns", [controller.get("ad.campaign")]);
      this.set("controllerData", {});
      let leadsIndexController = this.controllerFor("leads.index");
      controller.init(leadsIndexController.get("action"));
      leadsIndexController.set("action", null);
      this.subscribeToContactPhoneChannel(controller.get("model.lead.id"));
      controller.set("unsavedAttachments", []);
      controller.prepareCustomAttributes();
      controller.set("isRefreshingForm", false);
    },
    resetController: function (controller) {
      this._super.apply(this, arguments);
      controller.set("autoCall", false);
      controller.set("autoText", false);
      controller.set("selectedContact", null);
      controller.set("selectedContactEmail", null);
      this.unsubscribeFromContactPhoneChannel();
    },
    afterReceived(controller, payload, action) {
      let opportunity = controller.get("model");
      controller.get("store").query("lead-activity", {
        paginate: false,
        opportunity_id: opportunity.get("id")
      }).then(function (activities) {
        controller.set("activities", activities);

        controller.get("store").query("lead-activity", {
          paginate: false,
          filter: "filled_notes",
          opportunity_id: opportunity.get("id")
        }).then(function (notes) {
          controller.set("filledNoteActivities", notes);
        });

        if (action !== "destroy") {
          let activity = this.get("store").peekRecord("lead-activity", payload.data.id);

          if (["survey_email_sent", "survey_sent", "survey_email_sent", "rated_satisfaction", "user_graded_call", "review_request_sent", "review_link_opened"].includes(activity.get("key"))) {
            if (activity.get("key") === "review_request_sent" && Ember.isPresent(controller.get("selectedContact"))) {
              controller.get("selectedContact").hasMany("reviewLinksContacts").reload();
            }
            controller.send("reRenderContactActions");
            opportunity.belongsTo("lead").reload(); // reload number of Surveys Requested and Contacts Responded
          }

          if (!opportunity.get("hasDirtyAttributes")) {
            opportunity.reload().then(function () {
              if (["invoice_created", "quote_rejected", "estimate_created", "invoice_paid"].includes(activity.get("key"))) {
                opportunity.hasMany("salesTransactions").reload();
              }
            });
            // check if product is sold out
            if (activity.get("body.opportunity").salesStage.key === "closed" && opportunity.get("orders.firstObject.product.outAfterSold")) {
              let productId = opportunity.get("orders.firstObject.product.id");
              let product = controller.get("store").peekRecord("product", productId);
              product.reload();
            }
          } else {
            if (["invoice_created", "quote_rejected", "estimate_created", "invoice_paid"].includes(activity.get("key"))) {
              opportunity.hasMany("salesTransactions").reload();
            }
          }

          // reload contact phone numbers
          if (["contact_opted_in", "stop_texting", "request_sent", "contact_resubscribed"].includes(activity.get("key"))) {
            controller.set("isRefreshingSMSButtons", true);
            Ember.run.later(function () {
              controller.set("isRefreshingSMSButtons", false);
            }, 5);
          }
        }
      }.bind(this));
    },
    renderTemplate: function (controller, model) {
      return this.render("leads/show/opportunities/show", {
        into: "application",
        model: model
      });
    },
    rollbackInstance(instance, relationships) {
      instance.eachRelationship(function (relationship, relationshipMeta) {
        if (relationships.includes(relationship)) {
          if (relationshipMeta.kind === "belongsTo" && instance.get(`${relationship}HasChanged`)) {
            let canonicalState = instance.get("_internalModel._relationships").get(relationship).canonicalState;
            if (Ember.isPresent(canonicalState)) {
              instance.set(relationship, canonicalState.record);
            }
          }
          if (relationshipMeta.kind === "hasMany" && instance.get(`${relationship}HasChanged`)) {
            instance.get("_internalModel._relationships").get(relationship).canonicalState.invoke("rollbackAttributes");
          }
        }
      });
      instance.rollbackAttributes();
    },
    actions: {
      willTransition: function (transition) {
        let model = this.controller.get("model");
        let activities = this.controller.get("activities");
        activities.forEach(function (activity) {
          activity.set("selected", true);
        });
        this.controller.set("selectedActivity", null);
        this.controller.set("activitiesFilter", null);

        this.rollbackInstance(model, ["salesStage", "salesAction", "ad", "assignedTo"]);
        model.get("orders").then(function (orders) {
          orders.forEach(function (order) {
            if (order.get("isNew")) {
              order.deleteRecord();
            } else {
              this.rollbackInstance(order, ["product"]);
            }
          }.bind(this));
        }.bind(this));

        //model.set('note')

        //Clear localStorage when transition to other route that does not belong to leads.show
        if (!transition.params["leads.show"]) {
          this.get("lastOpportunity").clear();
        }
      }
    }
  });

  exports.default = LeadsShowOpportunitiesShowRoute;
});