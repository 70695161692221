define("knowme-client/routes/change-password", ["exports", "knowme-client/mixins/routes/unauthenticated-mixin"], function (exports, _unauthenticatedMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let ChangePasswordRoute;

  ChangePasswordRoute = Ember.Route.extend(_unauthenticatedMixin.default);

  exports.default = ChangePasswordRoute;
});