define("knowme-client/routes/account/settings/deals/edit", ["exports", "knowme-client/mixins/routes/account-setup"], function (exports, _accountSetup) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    model: function (params) {
      return this.get("store").find("deal", params.id);
    },
    afterModel: function () {
      return Ember.RSVP.hash({
        products: this.get("store").query("product", {
          paginate: false,
          sort_by: "name",
          order: "asc",
          status: "active"
        })
      }).then(function (results) {
        this.set("controllerData", results);
      }.bind(this));
    },
    setupController: function (controller) {
      this._super(...arguments);
      controller.setProperties(this.get("controllerData"));
      this.set("controllerData", {});
    },
    actions: {
      willTransition: function () /*transition*/{
        this.controller.get("model").rollbackAttributes();
      }
    }
  });
});