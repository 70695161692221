define("knowme-client/mixins/routes/account-setup", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    session: Ember.inject.service(),
    beforeModel: function (transition) {
      this._super(...arguments);
      this.get("session").requireAuthentication(transition, "login");
      let route = this;
      if (transition.isActive) {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          this.get("session.currentUser").then(function (user) {
            return user.get("didAllocation");
          }).then(function () /*roles*/{
            return route.get("session.currentUser").get("roles");
          }).then(function () /*roles*/{
            return this.get("session.currentUser");
          }.bind(this)).then(function (user) {
            return user.belongsTo("account").reload();
          }).then(function (account) {
            return account.get("subscription");
          }).then(function (subscription) {
            return subscription.get("plan");
          }).then(function () /*plan*/{
            let user = this.get("session.currentUser");
            if (Ember.isPresent(user)) {
              if (Ember.isBlank(user.get("account.agreementAcceptedAt"))) {
                if (user.get("isAdmin") || user.get("isClientAdmin")) {
                  this.set("acceptAgreementIsRequired", true);
                  if (this.routeName == "account.index") {
                    this.get("flashMessages").info("You need to accept the Knowme Client Agreement and Terms & Conditions", { destroyOnClick: true });
                    return resolve();
                  } else {
                    this.transitionTo("account.index").then(reject);
                  }
                } else {
                  if (transition.targetName !== "profile") {
                    transition.abort();
                    this.get("flashMessages").info("The administrator needs to accept the Knowme Client Agreement and Terms & Conditions, please contact with your administrator", { destroyOnClick: true });
                    this.transitionTo("profile").then(reject);
                  } else {
                    return resolve();
                  }
                }
              } else {
                if (!user.get("account.customerTypeConfirmed")) {
                  transition.abort();
                  this.transitionTo("wizard.client").then(reject);
                } else if (user.get("isKnowmeIqRequired") && !user.get("autopylotInstalled")) {
                  if (user.get("isProfileWizardRequired")) {
                    transition.abort();
                    this.transitionTo("wizard.profile").then(reject);
                  } else {
                    if (route.routeName !== "profile") {
                      transition.abort();
                      this.transitionTo("profile").then(reject);
                    }
                  }
                } else if (user.get("account.subscription.status") === "error") {
                  if (this.routeName !== "account.index") {
                    transition.abort();
                    this.transitionTo("account.index").then(reject);
                  }
                } else {
                  if (this.get("session.isEmailServerRunning")) {
                    this.get("session.currentUser.authentications").then(function (authentications) {
                      this.set("session.authenticatedWithGoogle", Ember.isPresent(authentications.findBy("provider", "google")));
                      this.set("session.authenticatedWithOutlook", Ember.isPresent(authentications.findBy("provider", "outlook")));
                      return resolve();
                    }.bind(this));
                  } else {
                    return resolve();
                  }
                }
              }
            } else {
              return resolve();
            }
          }.bind(this));
        }.bind(this));
      }
    },
    setupController: function () /*controller*/{
      this._super.apply(this, arguments);
      // rebuild scroller for left cached_navigation_property_values
      this.controllerFor("application").updateScroller();
    }
  });
});