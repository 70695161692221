define("knowme-client/components/deals/with-revenue-table", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    actions: {
      viewInvoiceDetails: function (item) {
        item.get("opportunity.lastSalesTransaction").then(function (invoice) {
          this.set("selectedInvoice", invoice);
          this.set("invoiceDetailsModal", true);
        }.bind(this));
      }
    }
  });
});