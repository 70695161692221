define("knowme-client/helpers/how-to-intl", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    accountService: Ember.inject.service("account"),
    features: Ember.inject.service(),
    compute([name, key]) {
      return this.get("intl").t(`how_to.${name}.${key}`, {
        numberType: this.get("features.isKnowmeIQFunnel") || this.get("features.isKnowmeIQToolbox") ? "AutoPylot" : "Knowme IQ",
        Customers: this.get("accountService.customersText"),
        customers: this.get("accountService.customersText").toLowerCase(),
        Customer: this.get("accountService.customerText"),
        customer: this.get("accountService.customerText").toLowerCase()
      });
    }
  });
});