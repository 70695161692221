define("knowme-client/adapters/texting-permission", ["exports", "knowme-client/adapters/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlTemplate: "{+scheme}://{+host}/{+namespace}/{+pathForType}{/id}{?view_as}",
    findManyUrlTemplate: "{+scheme}://{+host}/{+namespace}/{+pathForType}{?query*,view_as,paginate}",
    urlSegments: {
      view_as: function () {
        if (Ember.isPresent(this.get("session.impersonatedUser"))) {
          return this.get("session.impersonatedUser.id");
        }
      }
    }
  });
});