define("knowme-client/components/deals/purchases-table", ["exports", "moment", "ember-stripe-service/utils/load-script", "knowme-client/config/environment"], function (exports, _moment, _loadScript, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { publishableKey } = _environment.default.stripe;

  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    today: (0, _moment.default)(),
    modelPurchases: Ember.computed("model.length", "model.@each.purchases", function () {
      return this.get("model").mapBy("purchases");
    }),
    totalPurchases: Ember.computed.sum("modelPurchases"),
    estimateTitle: Ember.computed("accountDeal.opportunity.isClosed", "accountDeal.opportunity.chargeType", function () {
      if (Ember.isBlank(this.get("accountDeal"))) {
        return;
      }
      if (this.get("accountDeal.opportunity.isClosed")) {
        return Ember.isPresent(this.get("accountDeal.opportunity.chargeType")) ? this.get("accountDeal.opportunity.chargeType").replace(/_/g, " ") : "Invoice";
      }
      return "Estimate";
    }),
    actions: {
      openInvoice: function (accountDeal) {},
      openCheckout: function (accountDeal) {
        if (accountDeal.get("invoiceId")) {
          window.open(accountDeal.get("checkoutUrl"), "_blank").focus();
        } else {
          this.sendAction("openDeal", accountDeal.get("deal"));
        }
      },
      viewInvoiceDetails: function (invoiceId, accountDeal) {
        this.set("accountDeal", accountDeal);
        this.set("selectedDeal", accountDeal.get("deal"));
        this.get("store").findRecord("sales-transaction", invoiceId).then(function (invoice) {
          this.set("selectedInvoice", invoice);
          this.set("invoiceDetailsModal", true);
        }.bind(this));
      },
      doPrint: function () {
        const selector = ".sales-transaction";
        const options = {
          printDelay: 500,
          importCSS: true,
          importStyle: true
        };

        this.get("printThis").print(selector, options);
      },
      closeModal: function () {
        this.set("invoiceDetailsModal", false);
        this.set("selectedInvoice", null);
      },
      payNow: function (accountDeal) {
        console.log(accountDeal.get("sessionId"));
        (0, _loadScript.default)("https://js.stripe.com/v3/").then(function () {
          var stripe = Stripe(publishableKey);
          stripe.redirectToCheckout({
            sessionId: accountDeal.get("sessionId")
          }).then(function (data) {
            console.log(data);
          });
        });
      }
    }
  });
});