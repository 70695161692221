define("knowme-client/models/texting-permission", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    apNumber: (0, _relationships.belongsTo)("ap-number", { async: true }),
    contactPhoneNumber: (0, _relationships.belongsTo)("contact-phone-number", { async: true }),
    consentStatus: (0, _attr.default)(),
    createdAt: (0, _attr.default)("date"),
    updatedAt: (0, _attr.default)("date")
  });
});