define("knowme-client/controllers/leads/show/contacts", ["exports", "knowme-client/mixins/controllers/validations/leads/contacts-form", "knowme-client/mixins/models/ember-cp-validations-custom-properties", "knowme-client/mixins/controllers/status", "ember-local-storage"], function (exports, _contactsForm, _emberCpValidationsCustomProperties, _status, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_contactsForm.default, _emberCpValidationsCustomProperties.default, _status.default, {
    features: Ember.inject.service(),
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    accountService: Ember.inject.service("account"),
    opportunityShowController: Ember.inject.controller("leads.show.opportunities.show"),
    contactsController: Ember.inject.controller("leads.show.contacts"),
    applicationController: Ember.inject.controller("application"),
    queryParams: ["contact_id"],
    contact_id: null,
    email: null,
    emailDirty: false,
    phone: null,
    phoneDirty: false,
    address: null,
    addressDirty: false,
    newPrimary: null,
    displayConfirmationModal: false,
    displayNewLeadSuggestionModal: false,
    newLeadSuggestionAccepted: false,
    eachAttribute: function (iterator) {
      ["email", "phone", "address"].forEach(function (attribute) {
        iterator.call(this, attribute);
      });
    },
    isContactMerging: Ember.computed("contact_id", function () {
      return Ember.isPresent(this.get("contact_id"));
    }),
    onMerging: Ember.observer("contact_id", function () {
      if (Ember.isPresent(this.get("contact_id"))) {
        this.set("applicationController.isContactMerging", true);
        this.set("applicationController.contactId", this.get("contact_id"));
      } else {
        this.set("applicationController.isContactMerging", null);
        this.set("applicationController.contactId", null);
      }
    }),
    filteredModel: Ember.computed("model", "model.@each.status", "status", function (resource) {
      if (Ember.isPresent(this.get("status")) && this.get("status") !== "all") {
        return this.get("model").filterBy("status", this.get("status"));
      } else {
        return this.get("model");
      }
    }),
    actions: {
      merge: function (resource, contactId) {
        if (Ember.isPresent(this.get("selectedContact.fullName")) && Ember.isPresent(resource.get("fullName")) && resource.get("fullName") !== this.get("selectedContact.fullName")) {
          return;
        }
        resource.set("contactId", contactId);
        resource.save().then(function () {
          resource.get("contactInfos").forEach(function (contactInfo) {
            contactInfo.get("contactPhoneNumbers").reload();
          });
          this.set("contactsController.contact_id", null);
          resource.belongsTo("lead").reload();
          this.transitionToRoute("leads.show.contacts.edit", resource.get("id"));
        }.bind(this));
      },
      primaryClicked: function (contact) {
        if (contact.get("primary")) {
          return false;
        }
        if (this.get("session.currentUser.account.isbtoc")) {
          return false;
          // return this.set('displayNewLeadSuggestionModal', true);
        }
        this.set("newPrimary", contact);
        this.set("displayConfirmationModal", true);
      },
      confirmPrimary: function () {
        let controller = this;
        let originalPrimaryContact = this.get("primaryContact");
        let contact = this.get("newPrimary");
        this.set("displayConfirmationModal", false);
        contact.set("primary", !contact.get("primary"));
        contact.save().then(function () {
          controller.store.push({
            data: {
              id: originalPrimaryContact.get("id"),
              type: "contact",
              attributes: {
                primary: false
              }
            }
          });
          controller.set("newPrimary", null);
        });
      },
      confirmNewLead: function () {
        this.set("newLeadSuggestionAccepted", true);
      },
      closedModal: function () {
        if (this.get("newLeadSuggestionAccepted")) {
          this.transitionToRoute("leads.new");
        }
      }
    },
    // resetValidations: function() {
    //   this.set('errors', Errors.create());
    //   this.dependentValidationKeys = {};
    //   this.set('validators', Ember.A());
    //   this.buildValidators();
    //   this.validators.forEach(function(validator) {
    //     validator.addObserver('errors.[]', this, function(sender) {
    //       var errors = Ember.A();
    //       this.validators.forEach(function(validator) {
    //         if (validator.property === sender.property) {
    //           errors.addObjects(validator.errors);
    //         }
    //       }, this);
    //       this.set('errors.' + sender.property, errors);
    //     });
    //   }, this);
    // },
    workContactInfo: Ember.computed("resource.contactInfos.length", function () {
      return this.get("resource.contactInfos").find(function (contactInfo) {
        return contactInfo.get("category") === "work";
      });
    }),
    emailsCount: Ember.computed("resource.contactInfosContactEmails.length", "resource.contactInfosContactEmails.@each.isDeleted", function () {
      return Ember.isPresent(this.get("resource")) ? this.get("resource.contactInfosContactEmails").filterBy("isDeleted", false).length : 0;
    }),
    phonesCount: Ember.computed("resource.contactInfosContactPhoneNumbers.length", "resource.contactInfosContactPhoneNumbers.@each.isDeleted", function () {
      return Ember.isPresent(this.get("resource")) ? this.get("resource.contactInfosContactPhoneNumbers").filterBy("isDeleted", false).length : 0;
    }),
    addressesCount: Ember.computed("resource.contactInfosContactAddresses.length", "resource.contactInfosContactAddresses.@each.isDeleted", function () {
      return Ember.isPresent(this.get("resource")) ? this.get("resource.contactInfosContactAddresses").filterBy("isDeleted", false).length : 0;
    }),
    //feedback properties
    newEmailPlaceholder: Ember.computed("emailsCount", function () {
      return this.get("emailsCount") > 0 ? "Add Email" : "Email";
    }),
    newPhonePlaceholder: Ember.computed("phonesCount", function () {
      return this.get("phonesCount") > 0 ? "Add Phone" : "Phone";
    }),
    newAddressPlaceholder: Ember.computed("addressesCount", function () {
      return this.get("addressesCount") > 0 ? "Add Address" : "Address";
    }),
    emailFeedbackClass: Ember.computed("email", "phonesCount", function () {
      var classes;

      if (Ember.isPresent(this.get("email"))) {
        this.set("emailDirty", true);
      }
      if (this.get("emailDirty")) {
        if (Ember.isEmpty(this.get("validations.attrs.email.errors"))) {
          if (Ember.isPresent(this.get("email"))) {
            classes = "glyphicon-ok-sign text-success";
          } else {
            classes = "hide";
          }
        } else {
          if (Ember.isBlank(this.get("email"))) {
            classes = "glyphicon-asterisk text-danger sup";
          } else {
            classes = "glyphicon-remove text-danger";
          }
        }
      } else {
        if (this.get("validations.attrs.email.isRequired")) {
          // if required
          classes = "glyphicon-asterisk text-danger sup";
        } else {
          classes = "hide";
        }
      }

      return classes;
    }),
    phoneFeedbackClass: Ember.computed("phone", "emailsCount", function () {
      var classes;

      if (Ember.isPresent(this.get("phone"))) {
        this.set("phoneDirty", true);
      }
      if (this.get("phoneDirty")) {
        if (Ember.isEmpty(this.get("validations.attrs.phone.errors"))) {
          if (Ember.isPresent(this.get("phone"))) {
            classes = "glyphicon-ok-sign text-success";
          } else {
            classes = "hide";
          }
        } else {
          if (Ember.isBlank(this.get("phone"))) {
            classes = "glyphicon-asterisk text-danger sup";
          } else {
            classes = "glyphicon-remove text-danger";
          }
        }
      } else {
        if (this.get("validations.attrs.phone.isRequired")) {
          // if required
          classes = "glyphicon-asterisk text-danger sup";
        } else {
          classes = "hide";
        }
      }
      return classes;
    }),
    primaryContacts: Ember.computed.filter("model.@each.primary", function (contact /*, index, array*/) {
      return contact.get("primary");
    }),
    primaryContact: Ember.computed.alias("primaryContacts.firstObject"),
    currentContact: Ember.computed("contact", function () {
      return this.get("contact");
    }),
    onCurrentContactChange: Ember.observer("currentContact", function () {
      this.validate();
    }),
    resource: Ember.computed.alias("currentContact"),
    lead: Ember.computed.alias("model.lead"),
    lastOpportunity: (0, _emberLocalStorage.storageFor)("last-opportunity")
  });
});