define("knowme-client/serializers/deal", ["exports", "knowme-client/serializers/json-api"], function (exports, _jsonApi) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    normalize(typeClass, hash /*, prop*/) {
      if (hash.relationships.product.data) {
        // hash.links = {
        //   product: getOwner(this)
        //     .lookup("adapter:product")
        //     .buildURL(
        //       "product",
        //       hash.relationships.product.data.id,
        //       null,
        //       "findRecord",
        //       null
        //     ),
        // };
        hash.relationships = hash.relationships || {};
        hash.relationships["product"] = hash.relationships["product"] || {};
        hash.relationships["product"].links = hash.relationships.links || {};

        hash.relationships["product"].links.related = Ember.getOwner(this).lookup("adapter:product").buildURL("product", hash.relationships["product"].data.id, null, "findRecord", null);
      }

      return this._super.apply(this, arguments);
    }
  });
});