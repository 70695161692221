define("knowme-client/services/chat-notification", ["exports", "knowme-client/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),
    dataStore: Ember.inject.service("store"),
    notificationPopup: Ember.inject.service("notifications"),
    context: null,
    hifi: Ember.inject.service(),
    audio: null,
    init() {
      this._super(...arguments);

      //unread internal rooms
      // this.get('dataStore').query('room', {filter: 'unread'}).then(function(unreadRooms){
      //   console.log('unreadRoomsCount', unreadRooms.get('length'));
      //   this.get('context').set('unreadRoomsCount', unreadRooms.get('length'));
      // }.bind(this));

      // //unread contact rooms
      // this.get('dataStore').query('room', {filter: 'unread_contact'}).then(function(unreadRooms){
      //   console.log('unreadContactRoomsCount', unreadRooms.get('length'));
      //   this.get('context').set('unreadContactRoomsCount', unreadRooms.get('length'));
      // }.bind(this));

      // //untouched contact rooms
      // this.get('dataStore').query('room', {filter: 'untouched_contact'}).then(function(unreadRooms){
      //   console.log('untouchedContactRoomsCount', unreadRooms.get('length'));
      //   this.get('context').set('untouchedContactRoomsCount', unreadRooms.get('length'));
      // }.bind(this));

      // this.get('session').get('currentUser').then(function(currentUser){
      //   this.set('currentUser', currentUser);
      //   this.createConsumer({id: currentUser.get('id')});
      // }.bind(this));
    },
    setContext(context) {
      this.set("context", context);
    },
    cableService: Ember.inject.service("cable"),
    createConsumer: function (data) {
      this._super.apply(this, arguments);
      let self = this;
      let path = _environment.default.APP.chat_service.ws;
      let consumer = this.get("cableService").createConsumer(path);

      const subscription = consumer.subscriptions.create("ChatChannel", {
        connected() {
          console.log("ChatChannel#connected");
          this.perform("follow", data);
        },
        received(resp) {
          console.log("ChatChannel#received", resp);

          self.get("context").set("unreadRoomsCount", resp.unreadRoomsCount);

          let unreadContactRoomsCount = self.get("context").get("unreadContactRoomsCount");
          self.get("context").set("unreadContactRoomsCount", resp.unreadContactRoomsCount);

          let untouchedContactRoomsCount = self.get("context").get("untouchedContactRoomsCount");
          self.get("context").set("untouchedContactRoomsCount", resp.untouchedContactRoomsCount);

          let newContactStartedChat = resp.roomPayload && untouchedContactRoomsCount < resp.untouchedContactRoomsCount;
          if (newContactStartedChat) {
            let roomId = resp.roomPayload.data.id;
            Ember.run.later(function () {
              self.get("dataStore").pushPayload("room", resp.roomPayload);
              let room = self.get("dataStore").peekRecord("room", roomId);

              self.showNewContactChatNotification(room);
            }, 1000);
          } else {
            // if new message was received
            if (resp.isUnread) {
              let messageId = resp.messagePayload.data.id;
              Ember.run.later(function () {
                self.get("dataStore").pushPayload("chat-message", resp.messagePayload);
                self.get("dataStore").pushPayload("user", resp.userPayload);

                let chatMessage = self.get("dataStore").peekRecord("chat-message", messageId);
                let room = chatMessage.get("room");
                let isCurrentRoomOpened = Ember.getOwner(self).lookup("controller:application").currentPath === "chats.show";
                isCurrentRoomOpened = isCurrentRoomOpened && Ember.getOwner(self).lookup("router:main")._routerMicrolib.state.params["chats.show"].id === room.get("id");

                if (!isCurrentRoomOpened) {
                  if (Ember.isBlank(chatMessage.get("body"))) {
                    chatMessage.hasMany("attachments").reload().then(function () {
                      self.showNotification(chatMessage);
                    });
                  } else {
                    let isMessageToContact = room.get("kind") === "support" && chatMessage.get("ownerType") === "User";
                    if (!isMessageToContact) {
                      self.showNotification(chatMessage);
                    }
                  }
                }
              }, 1000);
            }
          }
        },
        disconnected() {
          console.log("ChatChannel#disconnected");
        }
      });
    },
    showNotification: function (chatMessage) {
      let self = this;
      let room = chatMessage.get("room");
      chatMessage.get("owner").then(function () {
        let user = chatMessage.get("owner");
        let imgUrl = "/assets/images/av1.png";
        if (user.get("avatarSmallUrl")) {
          imgUrl = user.get("avatarSmallUrl");
        }

        let text = `<div class="chat-message-info">
                    <div class="logo">
                      <img src="${imgUrl}" class="img-circle img-user media-object" alt="Profile Picture">
                    </div>
                    <div class="chat-title">
                      <span class="name">${user.get("name")}</span>
                      <span class="last-message">${chatMessage.get("preview")}</span>
                    </div>
                  </div>`;

        self.playPlucky();

        self.get("notificationPopup").info(text, {
          htmlContent: true,
          autoClear: true,
          clearDuration: 5000,
          cssClasses: "chat-message-notification",
          onClick: (popup, event) => {
            self.get("context").get("router").transitionTo("chats.show", room.get("id"), {
              queryParams: { kind: room.get("kind") }
            });
            self.get("notificationPopup").removeNotification(popup);
          }
        });
      });
    },
    showNewContactChatNotification: function (room) {
      let self = this;
      room.get("owner").then(function (contact) {
        let imgUrl = "/assets/images/av1.png";
        let text = `<div class="chat-message-info">
                    <div class="logo">
                      <img src="${imgUrl}" class="img-circle img-user media-object" alt="Profile Picture">
                    </div>
                    <div class="chat-title">
                      <span class="name">${contact.get("name")}</span>
                      <span class="last-message">Contact created web chat</span>
                    </div>
                  </div>`;

        self.playPlucky();

        self.get("notificationPopup").info(text, {
          htmlContent: true,
          autoClear: true,
          clearDuration: 5000,
          cssClasses: "chat-message-notification",
          onClick: (popup, event) => {
            self.get("context").get("router").transitionTo("chats.show", room.get("id"), {
              queryParams: { kind: room.get("kind") }
            });
            self.get("notificationPopup").removeNotification(popup);
          }
        });
      });
    },
    playPlucky: function () {
      if (Ember.isPresent(this.get("audio"))) {
        this.get("audio").play();
      } else {
        this.get("hifi").play("/plucky.mp3").then(function (audio) {
          this.set("audio", audio.sound);
        }.bind(this)).catch(function (error) {
          this.set("audio", null);
          console.log("Error", error);
        }.bind(this));
      }
    }
  });
});