define("knowme-client/routes/account/settings/deals/new", ["exports", "knowme-client/mixins/routes/account-setup"], function (exports, _accountSetup) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    queryParams: {
      from: {
        refreshModel: true
      }
    },
    dealAttributes: {},
    beforeModel: function (transition) {
      let dealAttributes = this.controllerFor("account.settings.deals").get("dealAttributes");
      if (Ember.isPresent(transition.queryParams.from) && Ember.isPresent(dealAttributes)) {
        this.set("dealAttributes", dealAttributes);
      }
    },
    model: function () {
      return this.store.createRecord("deal", this.get("dealAttributes"));
    },

    afterModel: function () {
      return Ember.RSVP.hash({
        products: this.get("store").query("product", {
          paginate: false,
          sort_by: "name",
          order: "asc",
          status: "active"
        })
      }).then(function (results) {
        this.set("controllerData", results);
      }.bind(this));
    },
    setupController: function (controller) {
      this._super(...arguments);
      controller.setProperties(this.get("controllerData"));
      this.set("controllerData", {});
    },
    actions: {
      willTransition: function () /*transition*/{
        let model = this.controller.get("model");
        if (model.get("isNew")) {
          model.deleteRecord();
        }
      }
    }
  });
});