define("knowme-client/routes/login", ["exports", "knowme-client/mixins/routes/unauthenticated-mixin"], function (exports, _unauthenticatedMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let LoginRoute;

  LoginRoute = Ember.Route.extend(_unauthenticatedMixin.default, {
    beforeModel: function () {
      if (this.paramsFor("login").changedPassword === "true") {
        let session = this.get("session");
        if (session.get("isAuthenticated")) {
          session.invalidate();
        }
        this.get("flashMessages").info("Your password has been changed successfully.");
      } else if (this.paramsFor("login").resetPassword === "true") {
        this.get("flashMessages").info("You will receive an email with instructions on how to reset your password in a few minutes..");
      } else {
        this._super(...arguments);
      }
    }
  });

  exports.default = LoginRoute;
});