define("knowme-client/controllers/account/settings/deals", ["exports", "knowme-client/mixins/controllers/pagination", "knowme-client/mixins/controllers/status", "knowme-client/mixins/controllers/queryable", "knowme-client/mixins/date-filter"], function (exports, _pagination, _status, _queryable, _dateFilter) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_dateFilter.default, _pagination.default, _status.default, _queryable.default, {
    queryParams: ["sort_by", "order", "page", "status", "query", "filter"],
    defaultsQueryParams: Ember.computed(function () {
      return { status: "active", sort_by: "revenue", order: "desc" };
    }),
    query: null,
    sort_by: null,
    order: null,
    router: Ember.inject.service(),
    firstContactEmail: Ember.computed.alias("emailInfo.contactEmails.firstObject"),
    firstContactNumber: Ember.computed.alias("phoneNumberInfo.contactPhoneNumbers.firstObject"),
    emailInfo: Ember.computed("contact", function () {
      if (Ember.isPresent(this.get("contact.contactInfos").filterBy("category", "work"))) {
        return this.get("contact.contactInfos").filterBy("category", "work").get("firstObject");
      } else {
        return this.get("store").createRecord("contact-info", {
          category: "work",
          contact: this.get("contact")
        });
      }
    }),
    phoneNumberInfo: Ember.computed("contact", "contact.contactInfos.@each.contactPhoneNumbers", function () {
      if (Ember.isPresent(this.get("contact.contactInfos").filterBy("category", "mobile"))) {
        return this.get("contact.contactInfos").filterBy("category", "mobile").get("firstObject");
      } else {
        return this.get("store").createRecord("contact-info", {
          category: "mobile",
          contact: this.get("contact")
        });
      }
    }),
    isContactTreeDirty: Ember.computed("contact.hasDirtyAttributes", "contact.contactInfosContactEmails.@each.contactInfoHasChanged", "contact.contactInfosContactPhoneNumbers.@each.contactInfoHasChanged", "contact.contactInfos.@each.hasDirtyAttributes", "contact.contactInfosContactEmails.@each.hasDirtyAttributes", "contact.contactInfosContactPhoneNumbers.@each.hasDirtyAttributes", function () {
      return this.get("contact.hasDirtyAttributes") || this.get("contact.contactInfosContactEmails").isAny("contactInfoHasChanged", true) || this.get("contact.contactInfosContactPhoneNumbers").isAny("contactInfoHasChanged", true) || this.get("contact.contactInfos").isAny("hasDirtyAttributes", true) || this.get("contact.contactInfosContactEmails").isAny("hasDirtyAttributes", true) || this.get("contact.contactInfosContactPhoneNumbers").isAny("hasDirtyAttributes", true);
    }),
    isDisabled: Ember.computed("opportunity.validations.isValid", "contact.validations.isValid", "isContactTreeDirty", "opportunity.orders.@each.hasDirtyAttributes", function () {
      console.log(this.get("opportunity.validations"));
      console.log(this.get("opportunity.validations.isInvalid"));
      console.log(this.get("contact.validations.isInvalid"));
      return this.get("opportunity.validations.isInvalid") || this.get("contact.validations.isInvalid");
    }),
    actions: {
      submit: function () {
        this.get("lead").save().then(function (_res) {
          this.get("flashMessages").success(`Ad ${this.get("selectedDeal").get("title")} was submitted successfully`, { destroyOnClick: true, timeout: 12000 });

          this.set("selectedDeal", null);
          this.set("lead", null);
        }.bind(this));
      },
      openDeal: function (deal) {
        if (deal.get("product").then) {
          deal.get("product").then(function (product) {
            let lead = this.set("lead", this.store.createRecord("lead", {
              source: "deal",
              dealId: deal.get("id")
            }));
            let contact = this.get("store").createRecord("contact", {
              lead: lead,
              primary: true
            });
            if (this.get("features.account.isbtob")) {
              this.store.createRecord("organization", { lead: lead });
            }
            let opportunity = this.store.createRecord("opportunity", {
              lead: lead,
              name: "Automatically Generated by Ads submit"
            });

            let order = this.store.createRecord("order", {
              opportunity: opportunity
            });

            order.set("product", product);

            opportunity.set("orders", [order]);
            this.set("lead", lead);
            this.set("opportunity", opportunity);
            this.set("contact", contact);
            this.set("selectedDeal", deal);
          }.bind(this));
        }
      },
      copyIncentive: function (resource) {
        let attrs = {
          name: resource.get("name"),
          body: resource.get("body")
        };
        this.set("dealAttributes", attrs);
        this.get("router").transitionTo("account.settings.incentives.new", {
          queryParams: { from: resource.get("id") }
        });
      },
      displaySelector: function (contactResource) {
        contactResource.set("displaySelector", !contactResource.get("displaySelector"));
      },
      changeCategory: function (category, contactResource) {
        contactResource.set("displaySelector", false);
        let contactInfo = contactResource.get("contactInfo");
        contactInfo.set("category", category);
      },
      addContactEmail: function () {
        var email = this.get("email");

        if (Ember.isPresent(email) && email.match(/^[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,}$/i)) {
          this.get("store").createRecord("contactEmail", {
            email: email,
            contactInfo: this.get("emailInfo")
          });
          this.set("email", "");
          Ember.run.later(function () {
            Ember.$(".email-input input").focus();
          }, 200);
        }
      },
      removeContactEmailIfEmpty: function (contactEmail) {
        if (Ember.isBlank(contactEmail.get("email"))) {
          contactEmail.deleteRecord();
        }
      },
      addContactPhoneNumber: function () {
        let phone = this.get("phone");
        if (Ember.isPresent(phone) && phone.length === 12) {
          this.get("store").createRecord("contactPhoneNumber", {
            tenDigitNumber: phone,
            contactInfo: this.get("phoneNumberInfo")
          });
          this.set("phone", "");
          Ember.run.later(function () {
            Ember.$(".phone-input input").focus();
          }, 200);
        }
      },
      removeContactPhoneIfEmpty: function (contactPhoneNumber) {
        if (Ember.isBlank(contactPhoneNumber.get("tenDigitNumber"))) {
          contactPhoneNumber.deleteRecord();
        }
      }
    }
  });
});