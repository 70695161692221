define("knowme-client/models/lead", ["exports", "ember-data/relationships", "ember-data/attr", "moment", "knowme-client/models/customizable-resource", "knowme-client/mixins/contactable", "knowme-client/mixins/models/validations/lead", "knowme-client/mixins/models/ember-cp-validations-custom-properties", "knowme-client/mixins/dirty"], function (exports, _relationships, _attr, _moment, _customizableResource, _contactable, _lead, _emberCpValidationsCustomProperties, _dirty) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _customizableResource.default.extend(_contactable.default, _lead.default, _emberCpValidationsCustomProperties.default, _dirty.default, {
    session: Ember.inject.service(),
    //relationships
    organization: (0, _relationships.belongsTo)("organization", { async: false }), // belongst to is used as hasOne http://emberjs.com/api/data/#toc_one-to-one
    opportunities: (0, _relationships.hasMany)("opportunity", { async: true }),
    communications: (0, _relationships.hasMany)("communication", { async: true }),
    unassignedCommunications: (0, _relationships.hasMany)("communication", { async: true }),
    activities: (0, _relationships.hasMany)("lead-activity", { async: true }),
    //fields
    status: (0, _attr.default)("string"), // options Active, Inactive
    source: (0, _attr.default)({ defaultValue: "campaign" }),
    leadId: (0, _attr.default)("number"),
    dealId: (0, _attr.default)("number"),
    organizationId: (0, _attr.default)("number"),
    createdAt: (0, _attr.default)("date"),
    updatedAt: (0, _attr.default)("date"),
    contactsSurveyRequestedCount: (0, _attr.default)("number"),
    contactsSurveyedCount: (0, _attr.default)("number"),
    reviewRequestedCount: (0, _attr.default)("number"),
    reviewLinkOpenedCount: (0, _attr.default)("number"),
    lastContactDate: (0, _attr.default)("date"),
    isAnonymous: (0, _attr.default)("boolean", { defaultValue: false }),
    isConverted: (0, _attr.default)("boolean", { defaultValue: false }),
    opportunitiesDisplayed: (0, _attr.default)("boolean", { defaultValue: true }),
    // computed data
    leadType: Ember.computed.alias("session.currentUser.account.customerType"),
    isbtob: Ember.computed.alias("session.currentUser.account.isbtob"),
    isbtoc: Ember.computed.alias("session.currentUser.account.isbtoc"),
    organizationName: Ember.computed("isbtob", "organization.name", "primaryContact.fullName", function () {
      if (this.get("isbtob") && Ember.isPresent(this.get("organization.name"))) {
        return this.get("organization.name");
      }

      if (Ember.isPresent(this.get("primaryContact.fullName"))) {
        return this.get("primaryContact.fullName");
      }

      return "Unknown";
    }),
    organizationNameOrPhoneNumber: Ember.computed("organizationName", "primaryContact.contactInfosContactPhoneNumbers.@each.formattedTenDigitNumber", function () {
      if (this.get("organizationName") === "Unknown") {
        return this.get("primaryContact.contactInfosContactPhoneNumbers.firstObject.formattedTenDigitNumber");
      } else {
        return this.get("organizationName");
      }
    }),
    opportunitiesLost: Ember.computed.filterBy("opportunities", "isLost", true),
    opportunitiesConverted: Ember.computed.filterBy("opportunities", "isClosed", true),
    opportunitiesNotConverted: Ember.computed.filterBy("opportunities", "isClosed", false),
    opportunitiesNotConvertedOrLost: Ember.computed.filter("opportunities", function (opp) {
      return !opp.get("isClosed") && !opp.get("isLost");
    }),
    opportunitiesTotals: Ember.computed.mapBy("opportunities", "total"),
    opportunitiesPipelines: Ember.computed("opportunities", function () {
      return this.get("opportunities.pipeline");
    }),
    opportunitiesRevenues: Ember.computed.mapBy("opportunities", "revenue"),
    opportunitiesOrdersCosts: Ember.computed.mapBy("opportunities", "ordersCosts"),
    total: Ember.computed.sum("opportunitiesTotals"),
    pipeline: Ember.computed("opportunities.length", "opportunities.@each.pipeline", "opportunities.@each.salesStageKey", function () {
      return this.get("opportunities").reduce(function (pipeline, opp) {
        if (!opp.get("isClosed") && !opp.get("isLost")) {
          pipeline += opp.get("pipeline");
        }
        return pipeline;
      }, 0);
    }),
    revenue: Ember.computed("opportunities.length", "opportunities.@each.pipeline", "opportunities.@each.salesStageKey", function () {
      return this.get("opportunities").reduce(function (pipeline, opp) {
        if (opp.get("isClosed")) {
          pipeline += opp.get("pipeline");
        }
        return pipeline;
      }, 0);
    }),
    profit: Ember.computed("opportunities.length", "opportunities.@each.pipeline", "opportunities.@each.salesStageKey", function () {
      return this.get("opportunities").reduce(function (profit, opp) {
        if (opp.get("isClosed")) {
          profit += opp.get("profit");
        }
        return profit;
      }, 0);
    }),
    ordersCostsTotal: Ember.computed.sum("opportunitiesOrdersCosts"),
    lastUpdatedOrderOpportunity: Ember.computed("opportunities.@each.lastUpdatedOrder", function () {
      let max;
      return this.get("opportunities").reduce(function (previousOpportunity, currentOpportunity) {
        max = previousOpportunity;
        if (Ember.isNone(previousOpportunity) || (0, _moment.default)(previousOpportunity.get("lastUpdatedOrder.updatedAt")).isBefore(currentOpportunity.get("lastUpdatedOrder.updatedAt"))) {
          max = currentOpportunity;
        }
        return max;
      });
    }),
    sortedOpportunities: Ember.computed.sort("opportunities", "sortDefinition"),
    sortBy: "updatedAt:desc",
    sortDefinition: Ember.computed("sortBy", function () {
      return [this.get("sortBy")];
    }),
    lastOpportunity: Ember.computed("sortedOpportunities", function () {
      return this.get("sortedOpportunities").get("firstObject");
    }),
    opportunitiesConvertedSorting: ["conversionDate"],
    opportunitiesConvertedSorted: Ember.computed.sort("opportunitiesConverted", "opportunitiesConvertedSorting"),
    firstConvertedOpportunity: Ember.computed("opportunitiesConvertedSorted", function () {
      return this.get("opportunitiesConvertedSorted.firstObject");
    }),
    lastConvertedOpportunity: Ember.computed("opportunitiesConvertedSorted", function () {
      return this.get("opportunitiesConvertedSorted.lastObject");
    }),
    lastUpdatedOpportunitiesOrder: Ember.computed.alias("lastUpdatedOrderOpportunity.lastUpdatedOrder"),
    isCustomer: Ember.computed("opportunities.@each.isClosed", function () {
      return this.get("opportunities").any(function (opportunity) {
        return opportunity.get("isClosed");
      });
    }),
    hasOpportunityWithCTP: Ember.computed("opportunities.@each.ad.callTouchPoints.@each.id", function () {
      return this.get("opportunities").any(function (opportunity) {
        return opportunity.get("ad.callTouchPoints.length") > 0;
      });
    }),
    hasSingleContact: Ember.computed("contacts.length", function () {
      return this.get("contacts.length") === 1;
    }),
    organizationNameOrContactName: Ember.computed("hasSingleContact", "organizationName", "primaryContact.fullName", function () {
      return this.get("hasSingleContact") ? this.get("primaryContact.fullName") : this.get("organizationName");
    }),
    isInactive: Ember.computed.equal("status", "inactive")
  });
});