define("knowme-client/mixins/models/validations/business-profile", ["exports", "ember-cp-validations", "moment"], function (exports, _emberCpValidations, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _emberCpValidations.buildValidations)({
    // account: validator("presence", {
    //   presence: true,
    //   ignoreBlank: true,
    // }),
    name: [(0, _emberCpValidations.validator)("presence", { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)("ds-error")],
    category: [(0, _emberCpValidations.validator)("presence", { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)("ds-error")],
    email: [(0, _emberCpValidations.validator)("presence", { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)("format", {
      type: "email",
      regex: /^[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,}$/i,
      allowBlank: true
    }), (0, _emberCpValidations.validator)("ds-error")],
    phoneNumber: [(0, _emberCpValidations.validator)("presence", { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)("length", { is: 10, allowBlank: true }), (0, _emberCpValidations.validator)("number", {
      allowString: true,
      integer: true
    }), (0, _emberCpValidations.validator)("exclusion", {
      in: ["0000000000"],
      message: "This value is not allowed."
    })],
    addressStreet: (0, _emberCpValidations.validator)("presence", {
      presence: true,
      ignoreBlank: true
    }),
    addressCity: (0, _emberCpValidations.validator)("presence", { presence: true, ignoreBlank: true }),
    addressState: (0, _emberCpValidations.validator)("presence", { presence: true, ignoreBlank: true }),
    addressCountry: (0, _emberCpValidations.validator)("presence", { presence: true, ignoreBlank: true })
  });
});