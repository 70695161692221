define("knowme-client/helpers/command-messanges-to-ctp", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    compute([contactPhoneNumber, opportunity, user]) {
      return this.get("store").query("short-message", {
        filter: "inbound,to_call_touch_point,command_messanges",
        contact_phone_number_id: contactPhoneNumber.get("id"),
        ad_id: opportunity.get("ad.id"),
        sort_by: "short_message_date",
        order: "desc",
        size: 1
      });
    }
  });
});