define("knowme-client/models/deal-view", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DealView;

  DealView = _model.default.extend({
    // Relationships
    user: (0, _relationships.belongsTo)("account", { async: true }),
    deal: (0, _relationships.belongsTo)("deal", { async: true }),
    createdAt: (0, _attr.default)("date"),
    updatedAt: (0, _attr.default)("date")
  });

  exports.default = DealView;
});