define("knowme-client/serializers/account-deal", ["exports", "knowme-client/serializers/json-api"], function (exports, _jsonApi) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    normalize(typeClass, hash /*, prop*/) {
      if (hash.relationships.deal.data) {
        hash.relationships = hash.relationships || {};
        hash.relationships["deal"] = hash.relationships["deal"] || {};
        hash.relationships["deal"].links = hash.relationships.links || {};

        hash.relationships["deal"].links.related = Ember.getOwner(this).lookup("adapter:deal").buildURL("deal", hash.relationships["deal"].data.id, null, "findRecord", null);
      }
      if (hash.relationships.opportunity.data) {
        hash.relationships["opportunity"] = hash.relationships["opportunity"] || {};
        hash.relationships["opportunity"].links = hash.relationships.links || {};

        hash.relationships["opportunity"].links.related = Ember.getOwner(this).lookup("adapter:opportunity").buildURL("opportunity", hash.relationships["opportunity"].data.id, null, "findRecord", null);
      }

      return this._super.apply(this, arguments);
    }
  });
});