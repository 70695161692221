define("knowme-client/routes/account/settings/business-profile/address", ["exports", "knowme-client/mixins/routes/account-setup"], function (exports, _accountSetup) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    setupController: function (controller) {
      controller.setStatesList();
    }
  });
});