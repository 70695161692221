define("knowme-client/controllers/account/settings/business-profile/address", ["exports", "lodash", "knowme-client/mixins/us-states"], function (exports, _lodash, _usStates) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_usStates.default, {
    router: Ember.inject.service(),
    businessProfileController: Ember.inject.controller("account.settings.business-profile"),
    model: Ember.computed.alias("businessProfileController.model"),
    states: [{ id: "", name: "Select Country" }],
    statePromt: "State",
    codeLabel: "Zip",
    onCountrySelect: Ember.observer("model.addressCountry", function () {
      this.setStatesList();
    }),
    setStatesList: function () {
      if (Ember.isPresent(this.get("model.addressCountry"))) {
        if (this.get("model.addressCountry") === "us") {
          this.set("states", this.get("usStates"));
          this.set("statePromt", "State");
          this.set("codeLabel", "Zip");
        } else {
          this.set("states", this.get("canadianProvinces"));
          this.set("statePromt", "Province");
          this.set("codeLabel", "Postal");
        }
        if (!this.get("states").mapBy("id").includes(this.get("model.addressState"))) {
          this.set("model.addressState", null);
        } else {
          let addressState = _lodash.default.find(this.get("states"), {
            id: this.get("model.addressState")
          });
          this.set("addressState", addressState);
        }
      } else {
        this.set("states", [{ id: "", name: "Select Country" }]);
      }
    },
    // resetAddressState: observer("states", function () {
    //   console.log("resetAddressState");
    //   if (
    //     !this.get("states").mapBy("id").includes(this.get("model.addressState"))
    //   ) {
    //     this.set("model.addressState", null);
    //   } else {
    //     let addressState = _.find(this.get("states"), {
    //       id: this.get("model.addressState"),
    //     });
    //     this.set("addressState", addressState);
    //   }
    // }),
    disableSubmit: Ember.computed("model.validations.attrs.addressCountry.isInvalid", "model.validations.attrs.addressCity.isInvalid", "model.validations.attrs.addressState.isInvalid", "model.validations.attrs.addressStreet.isInvalid", function () {
      return this.get("model.validations.attrs.addressCountry.isInvalid") || this.get("model.validations.attrs.addressCity.isInvalid") || this.get("model.validations.attrs.addressState.isInvalid") || this.get("model.validations.attrs.addressStreet.isInvalid");
    }),
    actions: {
      setState: function (addressState) {
        this.set("addressState", addressState);
        this.set("model.addressState", addressState.id);
      },
      back: function () {
        this.get("router").transitionTo("account.settings.business-profile.phone");
      },
      submit: function () {
        this.get("model").save().then(function () {
          this.get("router").transitionTo("account.settings.business-profile.website");
        }.bind(this));
      }
    }
  });
});