define("knowme-client/models/deal", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "knowme-client/mixins/models/validations/deal", "knowme-client/mixins/models/ember-cp-validations-custom-properties", "knowme-client/mixins/dirty", "moment"], function (exports, _model, _attr, _relationships, _deal, _emberCpValidationsCustomProperties, _dirty, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Deal;

  Deal = _model.default.extend(_deal.default, _emberCpValidationsCustomProperties.default, _dirty.default, {
    // Relationships
    account: (0, _relationships.belongsTo)("account", { async: true }),
    product: (0, _relationships.belongsTo)("product", { async: true }),
    // Attributes
    logoUrl: (0, _attr.default)("string"),
    logoThumbnailUrl: (0, _attr.default)("string"),
    logoSmallUrl: (0, _attr.default)("string"),
    logo: (0, _attr.default)(),
    title: (0, _attr.default)(),
    body: (0, _attr.default)(),
    viewCount: (0, _attr.default)("number", { defaultValue: 0 }),
    customersCount: (0, _attr.default)("number", { defaultValue: 0 }),
    leadsCount: (0, _attr.default)("number", { defaultValue: 0 }),
    percentOff: (0, _attr.default)("number", { defaultValue: 0 }),
    revenue: (0, _attr.default)("number", { defaultValue: 0 }),
    pipeline: (0, _attr.default)("number", { defaultValue: 0 }),
    status: (0, _attr.default)("string", { defaultValue: "active" }),
    startDate: (0, _attr.default)("date", {
      defaultValue() {
        return (0, _moment.default)().startOf("day").toDate();
      }
    }),
    idNumber: Ember.computed("id", function () {
      if (this.get("id").toString().length < 2) {
        return ("0" + this.get("id")).slice(-2);
      } else {
        return this.get("id");
      }
    }),
    endDate: (0, _attr.default)("date"),
    createdAt: (0, _attr.default)("date"),
    updatedAt: (0, _attr.default)("date"),
    isInactive: Ember.computed.equal("status", "inactive"),
    hasExpired: (0, _attr.default)("boolean"),
    accountName: (0, _attr.default)(),
    accountAddressMainStreet: (0, _attr.default)(),
    accountAddressCity: (0, _attr.default)(),
    accountAddressState: (0, _attr.default)(),
    accountAddressZip: (0, _attr.default)(),
    accountBillingEmail: (0, _attr.default)(),
    accountDisplayBillingPhone: (0, _attr.default)("boolean"),
    accountBillingPhone: (0, _attr.default)()
  });

  exports.default = Deal;
});