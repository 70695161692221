define("knowme-client/controllers/account/settings/business-profile/phone", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    router: Ember.inject.service(),
    businessProfileController: Ember.inject.controller("account.settings.business-profile"),
    model: Ember.computed.alias("businessProfileController.model"),
    disableSubmit: Ember.computed("model.validations.attrs.phoneNumber.isInvalid", function () {
      return this.get("model.validations.attrs.phoneNumber.isInvalid");
    }),
    actions: {
      back: function () {
        this.get("router").transitionTo("account.settings.business-profile.email");
      },
      submit: function () {
        this.get("model").save().then(function () {
          this.get("router").transitionTo("account.settings.business-profile.address");
        }.bind(this));
      }
    }
  });
});