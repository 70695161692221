define("knowme-client/controllers/dashboard", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service("session"),
    features: Ember.inject.service(""),
    appointmentsParams: {
      from_date: (0, _moment.default)().format("MM-DD-YYYY"),
      to_date: (0, _moment.default)().format("MM-DD-YYYY")
    },
    application: Ember.inject.controller("application"),
    leadsController: Ember.inject.controller("leads.index"),
    dealsController: Ember.inject.controller("account.settings.deals"),
    reportsController: Ember.inject.controller("reports.index"),
    campaignsController: Ember.inject.controller("campaigns"),
    salesStagesController: Ember.inject.controller("sales-stages"),
    customerExperienceController: Ember.inject.controller("customer-experience"),
    sorting: ["position:asc"],
    sortedUsersWidgets: Ember.computed.sort("model", "sorting"),
    params: {},
    navbarOptions: { hideMenu: true },
    today: (0, _moment.default)().format("MM-DD-YYYY"),
    oneYearAgo: (0, _moment.default)().subtract(1, "year").format("MM-DD-YYYY"),
    on_date: null,
    filter: null,
    converted_at: null,
    marginLeft: 0,
    displayTodaysAppointments: Ember.computed("appointments.length", function () {
      return this.get("appointments.length") > 0 && localStorage.getItem("firstTimeDashboardOpened");
    }),
    style: Ember.computed("marginLeft", function () {
      return "margin-left: " + this.get("marginLeft") + "%;";
    }),
    maxMarginLeft: Ember.computed("model.length", function () {
      return (this.get("model.length") - 4) * -25;
    }),
    init: function () {
      this._super();
      this.set("params", {
        leads: this.get("leadsController.queryParams"),
        ads: this.get("dealsController.queryParams"),
        customers: this.get("leadsController.queryParams"),
        salesStages: this.get("salesStagesController.queryParams"),
        reports: this.get("reportsController.queryParams"),
        defaultsAdsParams: this.get("dealsController.defaultsQueryParams"),
        defaultsReportsParams: this.get("reportsController.defaultsQueryParams"),
        customerExperienceParams: this.get("customerExperienceController.queryParams"),
        defaultsCustomerExperienceParams: Ember.copy(this.get("customerExperienceController.defaultsQueryParams")),
        campaigns: this.get("campaignsController.queryParams"),
        defaultsCampaignParams: this.get("campaignsController.defaultsQueryParams")
      });
      Ember.run.schedule("afterRender", this, function () {
        this.get("application").setupNifty();
      });
    },
    appointments: Ember.computed(function () {
      let today = (0, _moment.default)();
      return this.get("store").query("opportunity", {
        from_date: today.format("DD-MM-YYYY"),
        to_date: today.format("DD-MM-YYYY"),
        field: "appointment_date"
      });
    }),
    actions: {
      moveLeft: function () {
        this.set("marginLeft", this.get("marginLeft") - 25);
      },
      moveRight: function () {
        this.set("marginLeft", this.get("marginLeft") + 25);
      }
    }
  });
});