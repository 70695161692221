define("knowme-client/routes/account/settings/deals", ["exports", "lodash", "moment", "knowme-client/mixins/routes/account-setup", "ember-stripe-service/utils/load-script", "knowme-client/config/environment"], function (exports, _lodash, _moment, _accountSetup, _loadScript, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { publishableKey } = _environment.default.stripe;

  exports.default = Ember.Route.extend(_accountSetup.default, {
    features: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    pagination: {},
    queryParams: {
      status: {
        refreshModel: true
      },
      query: {
        refreshModel: true
      },
      filter: {
        refreshModel: true
      }
    },
    model: function (params) {
      let promise = null;
      params = _lodash.default.mapValues(_lodash.default.pickBy(params, _lodash.default.identity), function (value, key) {
        if (key === "from_date" || key === "to_date") {
          return (0, _moment.default)(value, "MM-DD-YYYY").format("DD-MM-YYYY");
        }
        return value;
      });
      if (params.filter === "purchases") {
        promise = this.get("store").query("account-deal", params);
      } else {
        promise = this.get("store").query("deal", params);
      }

      promise.then(result => {
        this.set("pagination.total", result.meta.pagination.totalCount);
        this.set("pagination.limit", result.meta.pagination.page.size);
        this.set("pagination.page", result.meta.pagination.page.number);

        this.set("selectedResource", null);
      });
      return promise;
    },
    afterModel: function () {
      this.set("controllerData", {
        account: this.get("features.account")
      });
    },
    setupController: function (controller) {
      this._super.apply(this, arguments);
      // Model is already set, so we have to explicitly update controller
      controller.setProperties(this.get("controllerData"));
      this.set("controllerData", {});

      controller.set("selectedResource", this.get("selectedResource"));
      controller.set("total", this.get("pagination.total"));
      controller.set("limit", this.get("pagination.limit"));
      controller.set("count", Math.ceil(this.get("pagination.total") / this.get("pagination.limit")));
      controller.set("current", this.get("pagination.page"));
    },
    actions: {
      reloadModel: function () {
        this.refresh();
      },
      queryParamsDidChange: function () {
        this.refresh();
      },
      redirectToCheckout: function (sessionId) {
        (0, _loadScript.default)("https://js.stripe.com/v3/").then(function () {
          var stripe = Stripe(publishableKey);
          stripe.redirectToCheckout({
            sessionId: sessionId
          }).then(function (data) {
            console.log(data);
          });
        });
      }
    }
  });
});