define("knowme-client/controllers/pricing", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var KnowmeIQProductController;

  KnowmeIQProductController = Ember.Controller.extend({
    interval: "monthly",
    init: function () {
      this._super();
      this.set("planSettings", {
        funnel: {
          fee: 65,
          totalUsers: 2,
          totalCallTouchPointsDidsLocal: 4,
          multiRings: 1,
          totalAutopylotNumbers: 0,
          totalMinutes: 350,
          totalMessages: 350
        },
        premium: {
          fee: 195,
          totalUsers: 2,
          totalCallTouchPointsDidsLocal: 4,
          multiRings: 1,
          totalAutopylotNumbers: 2,
          totalMinutes: 1500,
          totalMessages: 1500
        }
      });
    },
    actions: {
      toggleInteval: function () {
        if (this.get("interval") === "annually") {
          this.set("interval", "monthly");
        } else {
          this.set("interval", "annually");
        }
      },
      openSignUpForm: function (plan, fee) {
        let signupData = {
          interval: this.get("interval"),
          plan: plan,
          promoCode: this.get("promoCode"),
          fee: fee,
          planSettings: this.get(`planSettings.${plan}`)
        };

        this.set("transitioningToSignUpForm", true);
        this.set("signupData", signupData);
        this.transitionToRoute("signup");
      }
    }
  });

  exports.default = KnowmeIQProductController;
});