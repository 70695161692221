define("knowme-client/controllers/account/settings/business-profile/hours-of-operation", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    router: Ember.inject.service(),
    businessProfileController: Ember.inject.controller("account.settings.business-profile"),
    model: Ember.computed.alias("businessProfileController.model"),
    initWorkTimes: function () {
      if (this.get("model.isNew") || Ember.isBlank(this.get("model.workTimes")) || Object.keys(this.get("model.workTimes")).length === 0) {
        this.set("model.workTimes", this.get("defaultWorkTimes"));
      }
    },
    daysOfWeek: [{ key: "mon", title: "Mon" }, { key: "tue", title: "Tue" }, { key: "wed", title: "Wed" }, { key: "thu", title: "Thu" }, { key: "fri", title: "Fri" }, { key: "sat", title: "Sat" }, { key: "sun", title: "Sun" }],
    defaultWorkTimes: {
      mon: {
        open: true,
        from: { h: "09", m: "00", ampm: "AM" },
        to: { h: "06", m: "00", ampm: "PM" }
      },
      tue: {
        open: true,
        from: { h: "09", m: "00", ampm: "AM" },
        to: { h: "06", m: "00", ampm: "PM" }
      },
      wed: {
        open: true,
        from: { h: "09", m: "00", ampm: "AM" },
        to: { h: "06", m: "00", ampm: "PM" }
      },
      thu: {
        open: true,
        from: { h: "09", m: "00", ampm: "AM" },
        to: { h: "06", m: "00", ampm: "PM" }
      },
      fri: {
        open: true,
        from: { h: "09", m: "00", ampm: "AM" },
        to: { h: "06", m: "00", ampm: "PM" }
      },
      sat: {
        open: false,
        from: { h: "09", m: "00", ampm: "AM" },
        to: { h: "06", m: "00", ampm: "PM" }
      },
      sun: {
        open: false,
        from: { h: "09", m: "00", ampm: "AM" },
        to: { h: "06", m: "00", ampm: "PM" }
      }
    },
    hours: Array.apply(null, Array(12)).map(function (v, i) {
      if (i < 9) {
        return "0" + (i + 1);
      } else {
        return i + 1;
      }
    }),
    minutes: Array.apply(null, Array(12)).map(function (v, i) {
      if (i < 2) {
        return "0" + i * 5;
      } else {
        return i * 5;
      }
    }),
    amPm: ["AM", "PM"],
    disableSubmit: Ember.computed("model.validations.attrs.category.isInvalid", function () {
      return this.get("model.validations.attrs.category.isInvalid");
    }),
    actions: {
      toggleDay: function (day) {
        this.set(`model.workTimes.${day}.open`, !this.get(`model.workTimes.${day}.open`));
      },
      back: function () {
        this.get("router").transitionTo("account.settings.business-profile.website");
      },
      submit: function () {
        this.get("model").save().then(function () {
          this.get("router").transitionTo("account.settings.business-profile.products");
        }.bind(this));
      }
    }
  });
});