define("knowme-client/components/deals/our-ads-table", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    modelRevenues: Ember.computed("model.length", "model.@each.revenue", function () {
      return this.get("model").mapBy("revenue");
    }),
    totalRevenue: Ember.computed.sum("modelRevenues"),
    modelPipelines: Ember.computed("model.length", "model.@each.pipeline", function () {
      return this.get("model").mapBy("pipeline");
    }),
    totalPipeline: Ember.computed.sum("modelPipelines")
  });
});