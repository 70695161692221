define("knowme-client/controllers/leads/edit", ["exports", "knowme-client/mixins/lead-form"], function (exports, _leadForm) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let LeadsEditController;

  LeadsEditController = Ember.Controller.extend(_leadForm.default, {
    features: Ember.inject.service(),
    contactsController: Ember.inject.controller("leads.show.contacts"),
    actions: {
      submit: function () {
        let controller = this;
        this.model.save().then(function () /*lead*/{
          controller.setProperties({
            emailDirty: null,
            phoneDirty: null,
            addressDirty: null
          });
          controller.transitionToRoute("leads.show");
        }).catch(function (reason) {
          console.log("Errors", reason);
        });
      }
    }
  });

  exports.default = LeadsEditController;
});