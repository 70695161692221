define("knowme-client/components/dashboard/ad-network-widget", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    accountService: Ember.inject.service("account"),
    todayPeriod: Ember.computed("", function () {
      return {
        from_date: (0, _moment.default)().format("MM-DD-YYYY"),
        to_date: (0, _moment.default)().format("MM-DD-YYYY")
      };
    })
  });
});