define("knowme-client/routes/calls", ["exports", "lodash", "moment", "knowme-client/mixins/routes/account-setup", "knowme-client/mixins/routes/cable-setup"], function (exports, _lodash, _moment, _accountSetup, _cableSetup) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function formatDate(date) {
    return date.format("MM-DD-YYYY"); //FIXME: Rails expects DD-MM-YYYY instead of MM-DD-YY
  }

  exports.default = Ember.Route.extend(_accountSetup.default, _cableSetup.default, {
    channelName: "CommunicationChannel",
    features: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    createdCalls: [],
    queryParams: {
      type: { refreshModel: true },
      filter: { refreshModel: true },
      satisfaction: { refreshModel: true },
      from_date: { refreshModel: true },
      to_date: { refreshModel: true },
      campaign_id: { refreshModel: true },
      user_id: { refreshModel: true },
      assigned_id: { refreshModel: true },
      resource_id: { refreshModel: true },
      query: { refreshModel: true }
    },
    pagination: {},
    beforeModel: function (transition) {
      return this._super.apply(this, arguments).then(function () {
        if (!this.get("features.unifiedCommunicationsAvailable") && !this.get("features.isKnowmeIQFunnel")) {
          transition.abort();
          this.get("flashMessages").warning("Calls are not included in your plan", { destroyOnClick: true, timeout: 12000 });
          return this.transitionTo("dashboard");
        }
        if (transition.queryParams.satisfaction === "prompt") {
          return this.transitionTo({ queryParams: { satisfaction: null } });
        }
        return this.set("subscriptionData", {
          id: this.get("session.currentUser.id")
        });
      }.bind(this));
    },
    model: function (params) {
      let modelName = params.type === "inbound" ? "contact" : "user";
      this.set("resourceType", modelName);
      let promise = this.get("store").query(modelName, this.processParams(params));

      promise.then(result => {
        this.set("callsTotal", result.meta.callsTotal);
        if (params.type === "inbound" && Ember.isBlank(params.resource_id)) {
          this.set("pagination.total", result.meta.pagination.total);
          this.set("pagination.limit", result.meta.pagination.limit);
          this.set("pagination.page", result.meta.pagination.page);
        }
        if (params.resource_id) {
          this.set("selectedResource", result.findBy("id", params.resource_id));
        } else {
          this.set("selectedResource", null);
        }
      });
      return promise;
    },
    afterModel: function (model, transition) {
      let campaigns = this.get("store").query("campaign", {
        paginate: false,
        filter: "with_calls"
      });
      let users = this.get("store").query("user", {
        paginate: false,
        with_did: true,
        status: "active",
        sort_by: "name",
        order: "asc"
      });
      let assignedUsers = this.get("store").query("user", {
        paginate: false,
        filter: ["with_assigned_calls"],
        sort_by: "name",
        order: "asc"
      });
      let promises = {
        campaigns: campaigns,
        users: users,
        assignedUsers: assignedUsers
      };

      if (transition.queryParams.type === "inbound") {
        promises["usersWithInboundCalls"] = this.get("store").query("user", {
          paginate: false,
          filter: ["with_inbound_calls"],
          sort_by: "name",
          order: "asc"
        });
      }
      if (transition.queryParams.type === "outbound") {
        promises["usersWithOutboundCalls"] = this.get("store").query("user", {
          paginate: false,
          filter: ["with_outbound_calls"],
          sort_by: "name",
          order: "asc"
        });
      }

      if (this.get("selectedResource")) {
        promises["filteredCalls"] = this.get("store").query("call", this.processCallParams(transition.queryParams, this.get("selectedResource")));
      }

      return Ember.RSVP.hash(promises).then(function (results) {
        this.set("controllerData", results);
      }.bind(this));
    },
    processParams: function (_params) {
      let params = Ember.copy(_params);

      if (Ember.isPresent(params.resource_id)) {
        params.ids = [params.resource_id];
        delete params.resource_id;
        delete params.page;
      }
      if (Ember.isPresent(params.filter) || Ember.isPresent(params.satisfaction) || Ember.isPresent(params.from_date) && Ember.isPresent(params.to_date) || Ember.isPresent(params.user_id) || Ember.isPresent(params.assigned_id) || Ember.isPresent(params.campaign_id)) {
        params.calls = {};
      }
      if (Ember.isPresent(params.filter)) {
        params.calls.filter = [params.filter];
        delete params.filter;
      }

      if (Ember.isPresent(params.satisfaction)) {
        if (params.satisfaction !== "prompt") {
          params.calls.satisfaction = params.satisfaction;
        }
        delete params.satisfaction;
      }

      params.fields = ["last_call_id"];
      if (params.type === "inbound") {
        params.filter = "with_inbound_calls";
      }
      if (params.type === "outbound") {
        params.filter = "with_outbound_calls";
        params.fields.push("calls_count");
      }

      delete params.type;

      params = _lodash.default.mapValues(_lodash.default.pickBy(params, _lodash.default.identity), function (value, key) {
        if (key === "from_date" || key === "to_date" || key === "on_date" || key === "converted_at") {
          return (0, _moment.default)(value, "MM-DD-YYYY").format("DD-MM-YYYY");
        }
        return value;
      });

      if (Ember.isPresent(params.user_id)) {
        if (params.user_id === "all") {
          params.calls.users = "all";
        } else {
          params.calls.user_id = params.user_id;
        }
        delete params.user_id;
      }

      if (Ember.isPresent(params.assigned_id)) {
        if (params.assigned_id !== "all") {
          params.calls.assigned_id = params.assigned_id;
        }
        delete params.assigned_id;
      }

      if (Ember.isPresent(params.campaign_id)) {
        if (params.campaign_id === "all") {
          params.calls.campaigns = "all";
        } else {
          params.calls.campaign_id = params.campaign_id;
        }
        delete params.campaign_id;
      }

      if (Ember.isPresent(params.from_date) && Ember.isPresent(params.to_date)) {
        params.calls.from_date = params.from_date;
        params.calls.to_date = params.to_date;
        delete params.from_date;
        delete params.to_date;
      }

      return params;
    },
    processCallParams: function (_params, resource) {
      let options = {};
      let type = _params.type === "inbound" ? "contact" : "user";
      options[`${type}_id`] = resource.get("id");
      if (type === "contact") {
        options.filter = ["inbound"];
      } else if (type === "user") {
        options.filter = ["outbound"];
      }
      if (Ember.isPresent(_params.filter)) {
        options.filter.push(_params.filter);
      }
      if (Ember.isPresent(_params.user_id)) {
        if (_params.user_id === "all") {
          options.users = "all";
        } else {
          options.user_id = _params.user_id;
        }
      }
      if (Ember.isPresent(_params.assigned_id)) {
        if (_params.assigned_id !== "all") {
          options.assigned_id = _params.assigned_id;
        }
      }
      if (Ember.isPresent(_params.campaign_id)) {
        if (_params.campaign_id === "all") {
          options.campaigns = "all";
        } else {
          options.campaign_id = _params.campaign_id;
        }
      }
      if (Ember.isPresent(_params.satisfaction)) {
        options.satisfaction = _params.satisfaction;
      }
      let dateOptions = {
        from_date: _params.from_date,
        to_date: _params.to_date
      };
      let formattedDateOptions = this.processDateFilterParams(dateOptions);
      if (Ember.isPresent(formattedDateOptions.from_date) && Ember.isPresent(formattedDateOptions.to_date)) {
        Ember.assign(options, formattedDateOptions);
      }
      options.paginate = false;

      return options;
    },
    processDateFilterParams: function (params) {
      return _lodash.default.mapValues(_lodash.default.pickBy(params, _lodash.default.identity), function (value, key) {
        if (key === "from_date" || key === "to_date" || key === "on_date" || key === "converted_at") {
          return (0, _moment.default)(value, "MM-DD-YYYY").format("DD-MM-YYYY");
        }
        return value;
      });
    },
    setupController: function (controller, model) {
      this._super.apply(this, arguments);
      // Model is already set, so we have to explicitly update controller
      controller.set("resourceType", this.get("resourceType"));
      controller.set("callsTotal", this.get("callsTotal"));
      controller.set("selectedResource", this.get("selectedResource"));
      if (controller.get("type") === "inbound") {
        if (localStorage["callId"]) {
          this.get("store").findRecord("call", localStorage["callId"]).then(call => {
            localStorage.removeItem("callId");
            controller.set("selectedCall", call);
            Ember.run.later(this, function () {
              controller.set("selectedCall", null);
            }, 5000);
          });
        }
        controller.set("total", this.get("pagination.total"));
        controller.set("limit", this.get("pagination.limit"));
        controller.set("count", Math.ceil(this.get("pagination.total") / this.get("pagination.limit")));
      } else {
        controller.set("total", model.get("meta.pagination.totalCount"));
        controller.set("limit", model.get("meta.pagination.page.size"));
        controller.set("count", model.get("meta.pagination.totalPages"));
      }

      if (controller.get("resetPage")) {
        controller.set("current", 1);
      } else {
        controller.set("current", model.get("meta.pagination.page.number") || this.get("pagination.page"));
      }
      controller.setProperties(this.get("controllerData"));
      controller.set("modelLoaded", true);
      this.set("controllerData", {});
    },
    resetController: function (controller, isExiting /*, transition*/) {
      this._super(...arguments);
      if (isExiting) {
        if (controller.from_date && this.currentModel.query.calls) {
          let oldFromDate = formatDate((0, _moment.default)(this.currentModel.query.calls.from_date, "DD-MM-YYYY"));
          if (controller.from_date !== oldFromDate) {
            controller.set("resetPage", true);
          } else {
            controller.set("resetPage", null);
          }
        }
        controller.set("modelLoaded", false);
      }
    },
    afterReceived: function (controller, payload, action) {
      if (payload.data.attributes.resourceType === "Call") {
        let params = {};
        _lodash.default.each(controller.get("queryParams"), function (key) {
          params[key] = controller.get(key);
        });
        let call = this.get("store").peekRecord("call", payload.data.attributes.resourceId);
        switch (action) {
          case "create":
            if (!this.get("createdCalls").includes(payload.data.attributes.resourceId)) {
              this.get("createdCalls").pushObject(payload.data.attributes.resourceId);
              controller.set("modelLoaded", false);
            }
            call.reload().catch(function (res) {
              if (res.errors[0].status === "403") {
                // when opportunity is assigned to user with upper role
              }
            });
            break;
          case "update":
            if (Ember.isPresent(call) && call.get("currentState.stateName") !== "root.loaded.updated.inFlight") {
              call.reload().catch(function (res) {
                if (res.errors[0].status === "403") {
                  // when opportunity is assigned to user with upper role
                }
              });
            }
            break;
          case "destroy":
            break;
          default:
        }
        this.model(params).then(function (model) {
          controller.set("model", model);
          if (controller.get("selectedResource")) {
            this.get("store").query("call", this.processCallParams(params, controller.get("selectedResource"))).then(function (filteredCalls) {
              if (filteredCalls.get("length") === 0) {
                controller.set("resource_id", null);
              }
              controller.set("filteredCalls", filteredCalls);
            });
          }
          if (!controller.get("modelLoaded")) {
            controller.set("modelLoaded", true);
          }
        }.bind(this));
      }
    },
    actions: {
      setOrder: function (sortBy, order) {
        this.transitionTo({ queryParams: { sort_by: sortBy, order: order } });
      },
      changePage: function (current) {
        this.controller.set("previousPage", this.queryParams.page);
        this.transitionTo({ queryParams: { page: current } });
      },
      setQuery: function (query) {
        this.transitionTo({ queryParams: { query: query } });
      },
      refreshModel: function () {
        console.log("refreshing");
        this.refresh();
      },
      queryParamsDidChange: function () {
        this.refresh();
      },
      willTransition: function () /*transition*/{
        this.controller.set("modelLoaded", false);
      }
    }
  });
});