define("knowme-client/models/account-deal", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "knowme-client/mixins/models/validations/deal", "knowme-client/mixins/models/ember-cp-validations-custom-properties", "knowme-client/mixins/dirty", "moment"], function (exports, _model, _attr, _relationships, _deal, _emberCpValidationsCustomProperties, _dirty, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Deal;

  Deal = _model.default.extend(_deal.default, _emberCpValidationsCustomProperties.default, _dirty.default, {
    // Relationships
    account: (0, _relationships.belongsTo)("account", { async: true }),
    deal: (0, _relationships.belongsTo)("deal", { async: true }),
    opportunity: (0, _relationships.belongsTo)("opportunity", { async: true }),
    // Attributes
    checkoutUrl: (0, _attr.default)(),
    purchases: (0, _attr.default)("number", { defaultValue: 0 }),
    vendorName: (0, _attr.default)(),
    paymentId: (0, _attr.default)(),
    invoiceId: (0, _attr.default)(),
    sessionId: (0, _attr.default)(),
    paidAt: (0, _attr.default)(),
    leadAttributes: (0, _attr.default)("json"),
    startDate: (0, _attr.default)("date", {
      defaultValue() {
        return (0, _moment.default)().startOf("day").toDate();
      }
    }),
    endDate: (0, _attr.default)("date"),
    createdAt: (0, _attr.default)("date"),
    updatedAt: (0, _attr.default)("date")
  });

  exports.default = Deal;
});