define("knowme-client/models/call", ["exports", "ember-data/relationships", "ember-data/attr", "knowme-client/models/communication-resource"], function (exports, _relationships, _attr, _communicationResource) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _communicationResource.default.extend({
    session: Ember.inject.service(),
    //relationships
    contact: (0, _relationships.belongsTo)("contact", { async: true, inverse: "calls" }),
    contactPhoneNumber: (0, _relationships.belongsTo)("contact-phone-number", { async: true }),
    opportunity: (0, _relationships.belongsTo)("opportunity", { async: true }),
    user: (0, _relationships.belongsTo)("user", { async: true, inverse: "calls" }),
    did: (0, _relationships.belongsTo)("did", { async: true, inverse: "calls" }),
    assignedTo: (0, _relationships.belongsTo)("user", { async: true, inverse: "assignedCalls" }),
    listeners: (0, _relationships.hasMany)("user", { async: true, inverse: "listenedCalls" }),
    customerExperience: (0, _relationships.belongsTo)("customer-experience", {
      async: true,
      inverse: "customerExperienceResource"
    }),
    //fields
    contactPhoneNumberId: (0, _attr.default)("string"),
    from: (0, _attr.default)("string"),
    to: (0, _attr.default)("string"),
    callId: (0, _attr.default)("string"),
    callType: (0, _attr.default)("string"),
    callLength: (0, _attr.default)("string"),
    callRecordingLength: (0, _attr.default)("string"),
    callAudio: (0, _attr.default)("string"),
    callDate: (0, _attr.default)("string"),
    satisfaction: (0, _attr.default)(),
    listened: (0, _attr.default)("boolean"),
    tagList: (0, _attr.default)(),
    status: (0, _attr.default)(),
    insights: (0, _attr.default)("json"),
    summary: Ember.computed("insights", function () {
      if (Ember.isPresent(this.get("insights"))) {
        return this.get("insights.summary");
      }
    }),
    score: Ember.computed("insights", function () {
      if (Ember.isPresent(this.get("insights"))) {
        return this.get("insights.score");
      }
    }),
    transcription: Ember.computed("insights", function () {
      if (Ember.isPresent(this.get("insights"))) {
        return this.get("insights.transcription");
      }
    }),
    canBeRemoved: (0, _attr.default)("boolean"),
    isVoiceMail: Ember.computed("status", "callAudioIsPresent", function () {
      return (this.get("status") === "missed" || this.get("status") === "rejected") && this.get("callAudioIsPresent");
    }),
    isOutbound: Ember.computed("callType", function () {
      return this.get("callType") === "outbound";
    }),
    isAccepted: Ember.computed("status", function () {
      return this.get("status") === "started" || this.get("status") === "answered";
    }),
    isMissed: Ember.computed("status", "callAudioIsPresent", function () {
      return (this.get("status") === "missed" || this.get("status") === "rejected") && !this.get("callAudioIsPresent");
    }),
    createdAt: (0, _attr.default)("date"),
    updatedAt: (0, _attr.default)("date"),
    callAudioIsPresent: Ember.computed("callAudio", function () {
      return Ember.isPresent(this.get("callAudio"));
    }),
    callAudioWithParams: Ember.computed("callAudio", function () {
      return `${this.get("callAudio")}&type=mp3`;
    }),
    caller: Ember.computed("callType", function () {
      if (this.get("callType") === "inbound") {
        return this.get("contact");
      } else {
        return this.get("user");
      }
    }),
    allocatableModelName: Ember.computed.alias("did.didAllocation.allocatable._internalModel.modelName"),
    formattedFromNumber: Ember.computed("from", function () {
      if (Ember.isPresent(this.get("from"))) {
        if (this.get("from").match(/(\+\d+)(\d{3})(\d{3})(\d{4})/)) {
          return this.get("from").replace(/(\+\d+)(\d{3})(\d{3})(\d{4})/, "$1 ($2) $3-$4");
        } else {
          return this.get("from");
        }
      }
    }),
    formattedToNumber: Ember.computed("to", function () {
      if (Ember.isPresent(this.get("to"))) {
        if (this.get("to").match(/(\+\d+)(\d{3})(\d{3})(\d{4})/)) {
          return this.get("to").replace(/(\+\d+)(\d{3})(\d{3})(\d{4})/, "$1 ($2) $3-$4");
        } else {
          return this.get("to");
        }
      }
    }),
    formattedCallLength: Ember.computed("callLength", function () {
      return this.formatSecondsToMinutesAndSeconds(this.get("callLength"));
    }),
    formattedCallRecordingLength: Ember.computed("callRecordingLength", function () {
      return this.formatSecondsToMinutesAndSeconds(this.get("callRecordingLength"));
    }),
    formatSecondsToMinutesAndSeconds: function (seconds) {
      if (Ember.isBlank(seconds)) {
        return "";
      }
      seconds = parseInt(seconds);
      var calculatedMinutes = Math.floor(seconds / 60);
      var calculatedSeconds = seconds - calculatedMinutes * 60;

      var mDisplay = calculatedMinutes < 10 ? "0" + calculatedMinutes : calculatedMinutes;
      var sDisplay = calculatedSeconds < 10 ? "0" + calculatedSeconds : calculatedSeconds;

      return `${mDisplay}:${sDisplay}`;
    },
    deleteCall() {
      const adapter = this.store.adapterFor(this.constructor.modelName);
      return adapter.delete(this);
    }
  });
});