define("knowme-client/components/short-messages/table-header", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    features: Ember.inject.service(),
    tagName: "thead",
    columnLabel: Ember.computed("session.account.subscription.plan.productKey", function () {
      let label;
      let plan = this.get("session.account.subscription.plan.productKey");
      switch (plan) {
        case "marketing_optimizer_crm":
          label = "Text From";
          break;
        case "knowme_iq":
        case "sales_accelerator_marketing_optimizer_crm":
          if (Ember.isPresent(this.get("contact"))) {
            if (Ember.isPresent(this.get("shortMessage.did.didAllocation.allocatable.ad"))) {
              label = "Ad Name";
            } else {
              label = "User";
            }
          } else {
            label = "User or Ad Name";
          }
          if (this.get("features.isKnowmeIQFunnel")) {
            label = "Ad Name";
          }
          break;
        default:
          label = "User Name";
          break;
      }
      return label;
    })
  });
});