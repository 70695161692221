define("knowme-client/models/contact-phone-number", ["exports", "ember-data/relationships", "ember-data/attr", "knowme-client/mixins/models/validations/contact-phone-number", "knowme-client/mixins/models/ember-cp-validations-custom-properties", "knowme-client/mixins/dirty", "knowme-client/models/sales-transaction-target"], function (exports, _relationships, _attr, _contactPhoneNumber, _emberCpValidationsCustomProperties, _dirty, _salesTransactionTarget) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ContactPhoneNumber;

  ContactPhoneNumber = _salesTransactionTarget.default.extend(_contactPhoneNumber.default, _emberCpValidationsCustomProperties.default, _dirty.default, {
    contactInfo: (0, _relationships.belongsTo)("contact-info", { async: true }),
    calls: (0, _relationships.hasMany)("call", { async: true }),
    countryCode: (0, _attr.default)(),
    areaCode: (0, _attr.default)(),
    prefix: (0, _attr.default)(),
    suffix: (0, _attr.default)(),
    extension: (0, _attr.default)(),
    tenDigitNumber: (0, _attr.default)(),
    visible: (0, _attr.default)("boolean", { defaultValue: true }),
    masked: (0, _attr.default)("boolean", { defaultValue: false }),
    apConsentStatus: (0, _attr.default)("string"),
    allowedShortMessagesFromUser: (0, _attr.default)("boolean", { defaultValue: false }),
    requestedToStopShortMessagesFromUser: (0, _attr.default)("boolean", {
      defaultValue: false
    }),
    allowedToSendRequestPermissionShortMessage: (0, _attr.default)("boolean", {
      defaultValue: false
    }),
    hasRepliedYesToUser: (0, _attr.default)("boolean", { defaultValue: false }),
    hasRepliedStopToUser: (0, _attr.default)("boolean", { defaultValue: false }),
    hasRepliedStopToOtherUser: (0, _attr.default)("boolean", { defaultValue: false }),
    stoppedNumbers: (0, _attr.default)(),
    modifiable: (0, _attr.default)("boolean", { defaultValue: true }),
    deletable: (0, _attr.default)("boolean", { defaultValue: true }),
    allowedToSendTexts: (0, _attr.default)("boolean"),
    formattedTenDigitNumber: Ember.computed("tenDigitNumber", function () {
      if (Ember.isPresent(this.get("tenDigitNumber"))) {
        if (this.get("tenDigitNumber").match(/(\+\d+)(\d{3})(\d{3})(\d{4})/)) {
          return this.get("tenDigitNumber").replace(/(\+\d+)(\d{3})(\d{3})(\d{4})/, "$1 ($2) $3-$4");
        } else {
          return this.get("tenDigitNumber");
        }
      }
    }),
    formattedNumber: Ember.computed("formattedTenDigitNumber", "contactInfo.category", function () {
      return this.get("contactInfo.categoryFirstLetter") + " " + this.get("formattedTenDigitNumber");
    }),
    asString: Ember.computed("formattedTenDigitNumber", function () {
      return this.get("formattedTenDigitNumber");
    })
  });

  exports.default = ContactPhoneNumber;
});