define("knowme-client/routes/account/settings/business-profile/products", ["exports", "lodash", "knowme-client/mixins/routes/account-setup"], function (exports, _lodash, _accountSetup) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    queryParams: {
      status: {
        refreshModel: true
      },
      query: {
        refreshModel: true
      }
    },
    features: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    pagination: {},
    model: function () /*params*/{
      return this.get("session.currentUser").then(function (user) {
        return user.reload();
      }).then(function (user) {
        let product = this.store.createRecord("product", { photos: [] });
        return product;
      }.bind(this));
    },
    afterModel: function () /*model*/{
      return Ember.RSVP.hash({
        products: this.get("store").query("product", { status: "active" })
      }).then(function (results) {
        let result = results.products;
        this.set("pagination.total", result.meta.pagination.total);
        this.set("pagination.limit", result.meta.pagination.limit);
        this.set("pagination.page", result.meta.pagination.page);
        this.set("controllerData", results);
      }.bind(this));
    },
    processParams: function (_params) {
      let params = Ember.copy(_params);
      return _lodash.default.pickBy(params, _lodash.default.identity);
    },
    setupController: function (controller) {
      this._super.apply(this, arguments);
      // Model is already set, so we have to explicitly update controller
      controller.set("total", this.get("pagination.total"));
      controller.set("limit", this.get("pagination.limit"));
      controller.set("count", Math.ceil(this.get("pagination.total") / this.get("pagination.limit")));
      controller.set("current", this.get("pagination.page"));
      controller.setProperties(this.get("controllerData"));
      this.set("controllerData", {});
    }
  });
});