define("knowme-client/controllers/change-password", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ChangePasswordController;

  ChangePasswordController = Ember.Controller.extend({
    queryParams: { resetPasswordToken: "reset_password_token" },
    session: Ember.inject.service("session"),
    errorMessage: null,
    successMessage: null,
    loginProgress: 0,
    init: function () {
      this._super();
      this.set("inactiveLogout", false);
      if (localStorage.getItem("inactiveLogout")) {
        this.set("inactiveLogout", true);
        localStorage.removeItem("inactiveLogout");
      }
    },
    actions: {
      submit: function () {
        var controller = this;
        var password = controller.get("password");
        var passwordConfirmation = controller.get("passwordConfirmation");
        var resetPasswordToken = controller.get("resetPasswordToken");
        return Ember.run(function () {
          let adapter = Ember.getOwner(this).lookup("adapter:application");
          let url = adapter.buildURL("users/password");
          let data = {
            password: password,
            password_confirmation: passwordConfirmation,
            reset_password_token: resetPasswordToken
          };
          return Ember.$.ajax({
            url: url,
            method: "PUT",
            data: JSON.stringify(data),
            dataType: "json",
            contentType: "application/json"
          }).then(function () {
            controller.transitionToRoute("login", {
              queryParams: { resetPassword: null, changedPassword: true }
            });
          }).catch(function (response) {
            let error = "";
            for (let key in response.responseJSON.errors) {
              error = `${key.replace(/_/g, " ").capitalize()} ${response.responseJSON.errors[key]}`;
            }

            controller.set("errorMessage", error);
          });
        }.bind(this));
      }
    }
  });

  exports.default = ChangePasswordController;
});