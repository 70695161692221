define("knowme-client/controllers/forgot-password", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ForgotPasswordController;

  ForgotPasswordController = Ember.Controller.extend({
    session: Ember.inject.service("session"),
    errorMessage: null,
    successMessage: null,
    loginProgress: 0,
    init: function () {
      this._super();
      this.set("inactiveLogout", false);
      if (localStorage.getItem("inactiveLogout")) {
        this.set("inactiveLogout", true);
        localStorage.removeItem("inactiveLogout");
      }
    },
    actions: {
      submit: function () {
        var controller = this;
        var email = controller.get("email");
        return Ember.run(function () {
          let adapter = Ember.getOwner(this).lookup("adapter:application");
          let url = adapter.buildURL("users/password");
          let data = { email: email };
          return Ember.$.ajax({
            url: url,
            method: "POST",
            data: JSON.stringify(data),
            dataType: "json",
            contentType: "application/json"
          }).then(function () {
            controller.set("errorMessage", null);
            controller.transitionToRoute("login", {
              queryParams: { resetPassword: true, changedPassword: null }
            });
          }).catch(function () {
            controller.set("errorMessage", "Email not found");
          });
        }.bind(this));
      }
    }
  });

  exports.default = ForgotPasswordController;
});