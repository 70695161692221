define("knowme-client/components/addons-section", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    interval: "monthly",
    accountSettings: Ember.computed("interval", () => ["additional.users", "additional.users.dids.local", "additional.deals", "additional.minutes", "additional.short_messages", "additional.call_touch_points.dids.toll_free", "additional.call_touch_points.dids.local", "additional.call_touch_points.dids.metrics"]),
    didReceiveAttrs() {
      this._super(...arguments);
      this.initSettings();
      this.set("planData", {
        funnel: {
          fee: 45,
          totalUsers: 2,
          totalCallTouchPointsDidsLocal: 4,
          multiRings: 1,
          totalAutopylotNumbers: 0,
          totalMinutes: 300,
          totalMessages: 300,
          totalAds: 6
        },
        toolbox: {
          fee: 160,
          totalUsers: 2,
          totalCallTouchPointsDidsLocal: 8,
          multiRings: 1,
          totalAutopylotNumbers: 1,
          totalMinutes: 1000,
          totalMessages: 1000,
          totalAds: 20
        }
      });
    },
    initSettings() {
      this._super(...arguments);
      let _this = this;

      this.get("accountSettings").forEach(function (key) {
        let _key = key.camelize().capitalize();

        _this.set(`settings${_key}`, _this.get("store").createRecord("AccountSetting", {
          account: _this.get("model"),
          key: key,
          value: 0
        }));

        Ember.defineProperty(_this, `settings${_key}Cost`, Ember.computed("currentProduct.key", "currentPlan", `settings${_key}.value`, "promoCode", () => {
          var blockUnit = 1,
              unitPrice;
          if (Ember.isPresent(_this.get("planSettings")[_key.camelize() + "Block"])) {
            blockUnit = _this.get("planSettings")[_key.camelize() + "Block"];
            unitPrice = _this.get("planSettings")[_key.camelize() + "BlockFee"];
          } else {
            unitPrice = _this.get("planSettings")[_key.camelize() + "Fee"];
          }

          if (_key === "AdditionalUsers" && Ember.isPresent(_this.get("promoCode"))) {
            unitPrice = unitPrice - unitPrice * _this.get("promoCode.percentOff") / 100;
          }

          return parseInt(_this.get(`settings${_key}.value`), 10) / blockUnit * unitPrice;
        }));
      });
    },
    subscriptionFee: Ember.computed("currentProduct.key", "currentPlan", "interval", "settingsAdditionalUsersCost", "settingsAdditionalUsersDidsLocalCost", "settingsAdditionalMinutesCost", "settingsAdditionalShortMessagesCost", "settingsAdditionalCallTouchPointsDidsTollFreeCost", "settingsAdditionalCallTouchPointsDidsLocalCost", "settingsAdditionalCallTouchPointsDidsMetricsCost", "settingsAdditionalDealsCost", "planSettings.planFee", "promoCode", function () {
      let total = ["settingsAdditionalUsersCost", "settingsAdditionalUsersDidsLocalCost", "settingsAdditionalMinutesCost", "settingsAdditionalShortMessagesCost", "settingsAdditionalCallTouchPointsDidsTollFreeCost", "settingsAdditionalCallTouchPointsDidsLocalCost", "settingsAdditionalCallTouchPointsDidsMetricsCost", "settingsAdditionalDealsCost", "planSettings.planFee"].reduce(function (previousValue, item) {
        let cost = this.get(item) || 0;
        if (item === "planSettings.planFee" && Ember.isPresent(this.get("promoCode"))) {
          let withDiscount = cost - cost * this.get("promoCode.percentOff") / 100;
          return previousValue + withDiscount;
        } else {
          return previousValue + cost;
        }
      }.bind(this), 0);

      return total;
    }),
    planSettings: Ember.computed("plan.key", "interval", "promoCode.percentOff", function () {
      return (this.get("plan.settings") || {})[this.get("interval")];
    }),
    totalUsers: Ember.computed("settingsAdditionalUsers.value", "planSettings.includedUsers", function () {
      return this.get("settingsAdditionalUsers.value") + this.get("planSettings.includedUsers");
    }),
    totalUsersDidsLocal: Ember.computed("planSettings.includedUsersDidsLocal", "settingsAdditionalUsersDidsLocal.value", function () {
      return this.get("planSettings.includedUsersDidsLocal") + this.get("settingsAdditionalUsersDidsLocal.value");
    }),
    limitOfUsersDidsLocalExceeded: Ember.computed("totalUsers", "totalUsersDidsLocal", function () {
      return this.get("totalUsersDidsLocal") > this.get("totalUsers");
    }),
    actions: {
      addCallMetricsForMarketing: function () {
        if (this.get("settingsAdditionalCallTouchPointsDidsMetrics.value") === 1) {
          this.set("settingsAdditionalCallTouchPointsDidsMetrics.value", 0);
        } else {
          this.set("settingsAdditionalCallTouchPointsDidsMetrics.value", 1);
        }
      },
      applyPromoCode: function (promoCodeId, planKey) {
        if (Ember.isPresent(promoCodeId)) {
          let controller = this;
          this.get("store").query("promo-code", {
            coupon_id: promoCodeId,
            plan_key: planKey,
            billing_period: this.get("interval")
          }).then(function (promoCodes) {
            if (promoCodes.get("length") > 0) {
              if (planKey === "knowme_iq.toolbox") {
                controller.set("promoCode", promoCodes.get("firstObject"));
              }
              if (planKey === "knowme_iq.funnel") {
                controller.set("promoCode", promoCodes.get("firstObject"));
              }
            } else {
              controller.get("flashMessages").warning(`Promo Code ${promoCodeId} does not exist`, {
                destroyOnClick: true,
                timeout: 12000
              });
            }
          }, function (resp) {
            controller.get("flashMessages").warning(resp.errors[0].detail, {
              destroyOnClick: true,
              timeout: 12000
            });
          });
        }
      },
      openSignUpForm: function () {
        if (this.get("limitOfUsersDidsLocalExceeded")) {
          return;
        }

        let signupData = {
          interval: this.get("interval"),
          plan: this.get("plan"),
          promoCode: this.get("promoCode"),
          planData: this.get(`planData.${this.get("plan.planKey")}`)
        };

        this.get("accountSettings").forEach(function (key) {
          let _key = key.camelize().capitalize();
          signupData[`settings${_key}`] = this.get(`settings${_key}`);
        }.bind(this));

        // this.set('transitioningToSignUpForm', true);

        // this.sendAction("setSignupData", signupData);
        this.setSignupData(signupData);

        this.get("router").transitionTo("signup");
      }
    }
  });
});